import { types } from 'mobx-state-tree';

import { ProductShippingClassType } from '../types/ProductShippingClass';
import ShippingDeliveryTimeType, {
  ShippingDeliveryTimeCEFACTMap,
} from '../types/ShippingDeliveryTimeType';
import DeliveryTime from './DeliveryTime';
import Price from './Price';
import Service from './Service';
import ShippingCampaignInfo from './ShippingCampaignInfo';
import ShippingOptionSelectionField from './ShippingOptionSelectionField';

const ShippingOption = types
  .model('ShippingOption', {
    additional_services: types.array(Service),
    campaign_info: types.maybeNull(ShippingCampaignInfo),
    class: ProductShippingClassType,
    delivery_time: types.maybeNull(DeliveryTime),
    description: types.string,
    has_area_limitations: types.boolean,
    has_starting_price: types.boolean,
    id: types.identifierNumber,
    image: types.maybeNull(types.string),
    includes_low_order_fee: types.maybeNull(Price),
    low_order_fee_limit: types.maybeNull(Price),
    name: types.maybeNull(types.string),
    pickup_point_integration: types.maybeNull(types.string),
    price: Price,
    requires_pickup_point: types.boolean,
    selection_fields: types.array(ShippingOptionSelectionField),
  })
  .views((self) => {
    const getDeliveryTimes = (deliveryTime) => {
      let deliveryTimes = {
        minValue: null,
        maxValue: null,
      };

      switch (deliveryTime.type) {
        case ShippingDeliveryTimeType.HOURS:
          deliveryTimes = {
            minValue: Math.floor(deliveryTime.min / 24),
            maxValue: Math.ceil(deliveryTime.max / 24),
          };
          break;
        case ShippingDeliveryTimeType.WEEKS:
          deliveryTimes = {
            minValue: deliveryTime.min * 7,
            maxValue: deliveryTime.max * 7,
          };
          break;
        case ShippingDeliveryTimeType.MONTHS:
          deliveryTimes = {
            minValue: deliveryTime.min * 30,
            maxValue: deliveryTime.max * 30,
          };
          break;
        case ShippingDeliveryTimeType.BUSINESS_DAYS:
        default:
          deliveryTimes = {
            minValue: deliveryTime.min,
            maxValue: deliveryTime.max,
          };
          break;
      }

      return deliveryTimes;
    };

    return {
      get selectionFields() {
        return self.selection_fields;
      },
      getAnalyticsDeliveryTimes({
        deliveryTime = null,
        processingTime = null,
      }) {
        let deliveryTimes = {
          handlingTime: null,
          transitTime: null,
        };

        if (processingTime) {
          const { minValue, maxValue } = getDeliveryTimes(processingTime);
          deliveryTimes = {
            ...deliveryTimes,
            handlingTime: {
              minValue,
              maxValue,
              unitCode:
                ShippingDeliveryTimeCEFACTMap[
                  ShippingDeliveryTimeType.BUSINESS_DAYS
                ],
            },
          };
        }

        if (deliveryTime) {
          const { minValue, maxValue } = getDeliveryTimes(deliveryTime);
          deliveryTimes = {
            ...deliveryTimes,
            transitTime: {
              minValue,
              maxValue,
              unitCode:
                ShippingDeliveryTimeCEFACTMap[
                  ShippingDeliveryTimeType.BUSINESS_DAYS
                ],
            },
          };
        }

        return deliveryTimes;
      },
      getDefaultSelectionField() {
        return (
          (self.selection_fields &&
            self.selection_fields.length > 0 &&
            self.selection_fields[0] &&
            self.selection_fields[0].options.length > 0 &&
            self.selection_fields[0].options[0]) ||
          null
        );
      },
      getPrice(withTax = true) {
        if (self.price) {
          return self.price.getPrice(withTax);
        }

        return 0;
      },
    };
  });

export default ShippingOption;
