import { types } from 'mobx-state-tree';

import { ExtraFieldTypeType } from '../../types/ExtraFieldType';
import { OrderTypeType } from '../../types/OrderType';
import GdprData from '../analytics/GdprInfo';
import CustomFormField from '../CustomFormField';
import OrderPayment from '../order/OrderPayment';
import OrderShipping from '../order/OrderShipping';
import OrderStatusHistoryEntry from '../order/OrderStatusHistoryEntry';
import OrderTotalValue from '../order/OrderTotalValue';
import CartNewProduct from './CartNewProduct';
import CurrentOrderProduct from './CurrentOrderProduct';

const OrderExtraField = types.model('OrderExtraField', {
  id: types.number,
  name: types.string,
  type: ExtraFieldTypeType,
  value: types.maybeNull(
    types.union(
      types.string,
      types.number,
      types.array(types.string),
      types.array(types.number),
      types.boolean
    )
  ),
});

const CurrentOrder = types
  .model('CurrentOrder', {
    campaign_code: types.maybeNull(types.string),
    confirmation_email: types.maybeNull(types.string),
    currency: types.string,
    customer_fields: types.array(CustomFormField),
    date_purchased: types.maybeNull(types.string),
    external_type: types.maybeNull(types.string),
    extra_fields: types.array(OrderExtraField),
    gdpr_info: types.maybeNull(GdprData),
    has_licensed_products: types.boolean,
    header_content: types.maybeNull(types.string),
    html_widget: types.maybeNull(types.string),
    id: types.number,
    id_for_analytics: types.string,
    is_success_seen: types.boolean,
    merchant_name: types.maybeNull(types.string),
    new_product_info: types.maybeNull(CartNewProduct),
    payment: types.maybeNull(OrderPayment),
    payment_method_messages: types.optional(types.array(types.string), []),
    products: types.array(CurrentOrderProduct),
    real_total: types.number,
    receipt_url: types.maybeNull(types.string),
    removed_products: types.array(CurrentOrderProduct),
    show_prices: types.boolean,
    seller_name: types.maybeNull(types.string),
    separate_shipping_address_active: types.maybeNull(types.boolean),
    shipping: types.maybeNull(OrderShipping),
    status_history: types.optional(types.array(OrderStatusHistoryEntry), []),
    totals: types.array(OrderTotalValue),
    tracking_pixels: types.array(types.string),
    tracking_url: types.maybeNull(types.string),
    tracking_code: types.string,
    type: OrderTypeType,
  })
  .views((self) => {
    return {
      get customerInfo() {
        return self.payment?.address ? self.payment.address : null;
      },
      get paymentAddress() {
        return self.payment && self.payment.address;
      },
      get paymentMethod() {
        return self.payment && self.payment.method;
      },
      get pickupPoint() {
        return self.shipping && self.shipping.pick_up_point;
      },
      get shippingAddress() {
        return self.shipping && self.shipping.address;
      },
      get shippingMethod() {
        return self.shipping && self.shipping.method;
      },
    };
  });

export default CurrentOrder;
