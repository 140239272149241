import { types, getEnv, flow, getRoot } from 'mobx-state-tree';
import cookies from 'js-cookie';
import { CancelToken } from 'axios';

import User from '../models/User';
import { storeSessionId } from '../util/session';
import AccountModal from '../models/AccountModal';
import Session from '../models/Session';
import StatefulStore from '../models/StatefulStore';

const AccountStore = StatefulStore.named('AccountStore')
  .props({
    customerId: types.maybeNull(types.number),
    account: types.maybeNull(User),
    accountModal: types.optional(AccountModal, {}),
    session: types.maybeNull(Session),
    default_language_code: types.maybeNull(types.string),
    loggedInStatus: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get loggedIn() {
      if (!self.account) {
        return false;
      }
      return self.account.is_logged
        ? self.account.is_logged === self.loggedInStatus
        : false;
    },
    get isRetailer() {
      return self.account && self.account.is_retailer
        ? self.account.is_retailer
        : false;
    },
    get showPricesWithTax() {
      return self.account ? self.account.show_prices_with_tax : true;
    },
    get isViewOnly() {
      return self.account ? self.account.view_only : false;
    },
    get isBalanceBuyer() {
      // Can buy balance for subusers
      return getRoot(self).configStore
        ? getRoot(self).configStore.account.isMoneyTransfer
        : false;
    },
    get isBalanceUser() {
      // Can use balance
      const configValue = getRoot(self).configStore
        ? getRoot(self).configStore.account.isMoneyTransfer
        : false;

      return (
        configValue &&
        self.account &&
        self.account.is_customer_user &&
        !!self.account.user_id
      );
    },
    get getUserId() {
      return self.account && self.account.user_id;
    },
    get isCustomerUser() {
      return self.account && self.account.is_customer_user;
    },
    get isClientLoginEnabled() {
      return self.account && self.account.is_client_login_enabled;
    },
    get isClient() {
      return self.account && self.account.is_logged_as_client;
    },
    get name() {
      const company = self.account.personal_info.company;
      const name =
        self.account.personal_info.firstname +
        ' ' +
        self.account.personal_info.lastname;
      return company.length > 0 ? company : name;
    },
    get showCartMatrix() {
      return self.account && self.account.show_cart_matrix;
    },
    get showMultiProductMatrix() {
      return self.account && self.account.multi_product_matrix;
    },
    get showPrices() {
      return self.account && self.account.show_product_prices;
    },
    get isUserAllowedAddressModification() {
      return self.account && self.account.allow_address_modifications;
    },
    get getCustomerGroupId() {
      return self.account ? self.account.customer_group_id : null;
    },
  }))
  .actions((self) => {
    let source;

    return {
      setup: (account) => {
        self.setAccount(account);
        self.setLoading(false);
      },
      setAccount: (account) => {
        if (account) {
          self.account = account.user;
          self.customerId = account.user.customer_id;
          self.session = account.session;
          self.default_language_code = account.default_language_code;
          self.setLoggedInStatus(account.user.is_logged);
          storeSessionId(account.session_id);
        }
      },
      setLoggedInStatus: (status) => {
        self.loggedInStatus = status;
      },
      setInfo: (info) => {
        self.customerId = info.customer_id;
        self.account.personal_info.telephone = info.personal_info.telephone;
        self.account.personal_info.company = info.personal_info.company;
      },
      setClients: (clients) => {
        self.account.clients = clients;
      },
      loadAccount: flow(function* loadAccount() {
        self.setLoading(true);
        let accountResponse, infoResponse;
        try {
          [accountResponse, infoResponse] = yield Promise.all([
            getEnv(self).apiWrapper.request(`account`),
            getEnv(self).apiWrapper.request(`my-account/info`),
          ]);
        } catch (error) {
          if (
            error.accountResponse?.status === 401 ||
            error.accountResponse?.status === 429
          ) {
            accountResponse = error.accountResponse.data;
          } else {
            self.setError(error);
            throw error;
          }

          throw error;
        }
        self.setAccount(accountResponse);
        self.setInfo(infoResponse);
        self.setLoading(false);
      }),
      loadClients: flow(function* loadClients(text) {
        self.setLoading(true);
        try {
          const request = yield getEnv(self).apiWrapper.request(
            `account/clients`,
            {
              params: {
                q: text,
              },
            }
          );
          self.setClients(request);
        } catch (error) {
          self.setError(error);
          throw error;
        }

        self.setLoading(false);
      }),
      loginClient: flow(function* loginClient(selectedClient) {
        try {
          const response = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`account/client-login`, {
              selectedClient,
            });
          self.account = response.data.user;

          cookies.remove('customer_hash');
          return response.data;
        } catch (error) {
          if (
            error.response?.status === 401 ||
            error.response?.status === 429
          ) {
            return error.response.data;
          } else {
            throw error;
          }
        }
      }),
      login: flow(function* login(username, password, productListProducts) {
        try {
          const response = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`account/login`, {
              username,
              password,
              productListProducts,
            });
          self.account = response.data.user;

          cookies.remove('customer_hash');
          return response.data;
        } catch (error) {
          if (
            error.response?.status === 401 ||
            error.response?.status === 429
          ) {
            return error.response.data;
          } else {
            throw error;
          }
        }
      }),
      loginCheck: flow(function* loginCheck() {
        // If we already have a query running, cancel it.
        if (source) {
          source.cancel();
        }
        source = CancelToken.source();

        try {
          const response = yield getEnv(self).apiWrapper.request(
            `account/login-check`,
            { cancelToken: source.token }
          );
          self.setLoggedInStatus(response.is_logged);
        } catch (error) {
          throw error;
        }
      }),
      logout: flow(function* logout() {
        const response = yield getEnv(self)
          .apiWrapper.apiAxios()
          .post(`account/logout`);
        self.account = null;
        // Reload in order to refresh everything
        // When logout_url is defined it will be assigned
        if (response.data.logout_url && response.data.logout_url.length > 0) {
          window.location.assign(response.data.logout_url);
        } else {
          window.location.reload();
        }
      }),
      refreshCoupons: flow(function* loadAccount() {
        const response = yield getEnv(self).apiWrapper.request(
          `account/refresh-coupons`
        );
        self.session.addCoupons(response.activated_coupons);
      }),
      updateSessionParams: flow(function* updateSessionParams(params) {
        try {
          const response = yield getEnv(self).apiWrapper.request(
            `account/session`,
            {
              params,
            }
          );
          self.setAccount(response);
        } catch (error) {
          if (
            error.response?.status === 401 ||
            error.response?.status === 429
          ) {
            // logged out user, request should still be successful
            self.setAccount(error.response.data);
          } else {
            throw error;
          }
        }
      }),
      register: flow(function* (userData, productListProducts) {
        userData['productListProducts'] = productListProducts;

        try {
          const response = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`account/register`, userData);

          cookies.remove('customer_hash');

          return response;
        } catch (error) {
          throw error;
        }
      }),
      resetPassword: (email) =>
        getEnv(self).apiWrapper.apiAxios().post(`account/reset-password`, {
          email,
        }),
      setPasswordWithToken: (renewToken, password, confirmPassword) =>
        getEnv(self).apiWrapper.apiAxios().put(`account/set-new-password`, {
          renewToken,
          password,
          password_confirmation: confirmPassword,
        }),
    };
  });

export default AccountStore;
