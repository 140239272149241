import { DateTime } from 'luxon';

import { useCallback } from 'react';
import ProductAvailabilityType from '../../types/ProductAvailabilityType';
import { roundWithPrecision } from '../../util/number';

const useProductSchema = ({
  accountStore,
  configStore,
  currencyStore,
  countryStore,
  routeService,
  locationContext,
}) => {
  const getProductSchema = useCallback((product) => {
    const productSchema = {};

    const baseUrl = `${locationContext.protocol}//${locationContext.host}`;
    const productUrl = `${baseUrl}${routeService.getProductPath(product)}`;
    const withTax = accountStore.showPricesWithTax;
    const priceCurrency = currencyStore.currencyCode;
    const activeCountry = countryStore.activeCountry;

    const shouldShowModel = () => {
      return configStore.product.showModel;
    };

    const getProductSchemaName = () => {
      if (shouldShowModel() && product.model) {
        return `${product.name} ${product.model}`;
      }

      return product.name;
    };

    const getImageUrls = () => {
      const images = product.getImagesBySize('full').map((imagePathOrUrl) => {
        if (imagePathOrUrl.includes('http')) {
          return imagePathOrUrl;
        }

        return `${baseUrl}${imagePathOrUrl}`;
      });

      return images;
    };

    productSchema['@type'] = 'Product';
    productSchema.name = getProductSchemaName();
    productSchema.gtin = product.ean;
    productSchema.image = getImageUrls();
    productSchema.sku = product.id;
    productSchema.description =
      product.description_short || product.seoDescription;
    productSchema.mpn = product.manufacturer_product_id;

    if (shouldShowModel()) {
      productSchema.model = product.model;
    }

    const getShippingDetails = () => {
      const shippingDetails = product.shippingDetails.get(product.id);

      if (shippingDetails) {
        return shippingDetails.options
          .map((shippingOption) => {
            if (!shippingOption.delivery_time) {
              return;
            }

            const { handlingTime } = shippingOption.getAnalyticsDeliveryTimes({
              processingTime: product.processing_time,
            });
            const { transitTime } = shippingOption.getAnalyticsDeliveryTimes({
              deliveryTime: shippingOption.delivery_time,
            });

            return {
              '@type': 'OfferShippingDetails',
              deliveryTime: {
                '@type': 'ShippingDeliveryTime',
                handlingTime: {
                  '@type': 'QuantitativeValue',
                  minValue: handlingTime.minValue,
                  maxValue: handlingTime.maxValue,
                  unitCode: handlingTime.unitCode,
                },
                transitTime: {
                  '@type': 'QuantitativeValue',
                  minValue: transitTime.minValue,
                  maxValue: transitTime.maxValue,
                  unitCode: transitTime.unitCode,
                },
              },
              shippingDestination: {
                '@type': 'DefinedRegion',
                addressCountry: activeCountry.iso_code_2,
              },
              shippingRate: {
                '@type': 'MonetaryAmount',
                value: shippingOption.getPrice(withTax),
                currency: priceCurrency,
              },
            };
          })
          .filter((shippingDetails) => shippingDetails);
      }

      return [];
    };

    const getAvailability = () => {
      if (
        !product.availability_html ||
        product.availability_html.length === 0
      ) {
        return null;
      }

      if (product.availability_type === ProductAvailabilityType.ONLY_IN_SHOP) {
        return 'https://schema.org/InStoreOnly';
      } else if (
        product.free_quantity === 0 &&
        product.availability_type === ProductAvailabilityType.ALLOW_BACKORDER
      ) {
        return 'https://schema.org/BackOrder';
      } else if (product.free_quantity > 0) {
        return 'https://schema.org/InStock';
      }
    };

    const getValidProductPriceDate = () => {
      if (!product.price_info) {
        return null;
      }

      return DateTime.fromISO(product.price_info.valid_until).toISODate();
    };

    const getProductOfferSchema = () => {
      const calculatePrice = () => {
        let price = 0;

        if (product.price_info) {
          const roundedPriceWithPrecision = roundWithPrecision(
            product.price_info.getPrice(withTax),
            currencyStore.activeCurrencyPrecision
          );

          // Round price again by how many decimals are shown on product page
          price = roundWithPrecision(
            roundedPriceWithPrecision,
            currencyStore.activeCurrencyDecimalShown
          );
        }

        return price;
      };

      const baseOffer = {
        '@type': 'Offer',
        priceCurrency,
        url: productUrl,
        price: calculatePrice(),
        priceValidUntil: getValidProductPriceDate(),
        availability: getAvailability(),
        shippingDetails: getShippingDetails(),
      };

      return baseOffer;
    };

    if (product.mainCategory) {
      productSchema.category = product.mainCategory.hierarchy
        .map((category) => category.name)
        .join('>');
    }

    if (configStore.productPage.showWeight) {
      productSchema.weight = {
        '@type': 'QuantitativeValue',
        value: product.display_weight,
        unitCode: product.stock_unit,
      };
    }

    productSchema.offers = getProductOfferSchema();

    return productSchema;
  }, []);

  return [getProductSchema];
};

export default useProductSchema;
