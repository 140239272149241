import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import { Container } from 'reactstrap';

import ContentPopups from '../../components/ad/ContentPopups';
import MainBanner from '../../components/ad/MainBanner';
import CategoryGrid from '../../components/category/CategoryGrid';
import HelperNavigation from '../../components/common/HelperNavigation';
import PageTitle from '../../components/common/PageTitle';
import ScrollReset from '../../components/common/ScrollReset';
import WysiwygContent from '../../components/common/WysiwygContent';
import CanonicalLink from '../../components/head/CanonicalLink';
import RobotsMeta from '../../components/head/RobotsMeta';
import SEODescription from '../../components/head/SEODescription';
import SEOTitle from '../../components/head/SEOTitle';
import Page from '../../components/layout/Page';
import SidebarLayout from '../../components/layout/SidebarLayout';
import ContentForState from '../../components/loader/ContentForState';
import NavigationCategorySlider from '../../components/navigation/NavigationCategorySlider';
import NavigationSimpleCategoryList from '../../components/navigation/NavigationSimpleCategoryList';
import MainProductList from '../../components/product-list/MainProductList';
import ProductPdf from '../../components/product-pdf/ProductPdf';
import ProductTabs from '../../components/product/ProductTabs';
import DefaultSidebar from '../../components/sidebar/DefaultSidebar';
import PageSkeleton from '../../components/skeleton/PageSkeleton';
import SidebarSkeleton from '../../components/skeleton/SidebarSkeleton';
import SuitableProductFinder from '../../components/suitable-product/SuitableProductFinder';
import { modelOf } from '../../prop-types';
import RouteService from '../../services/RouteService';
import AdStore from '../../store/AdStore';
import CategoryStore from '../../store/CategoryStore';
import ConfigStore from '../../store/ConfigStore';
import SectionStore from '../../store/SectionStore';
import UIStore from '../../store/UIStore';
import AdZones from '../../types/AdZones';
import CommonPage from '../../types/CommonPage';
import Paths from '../../types/Paths';
import ProductPdfType from '../../types/ProductPdfType';
import { pathFragmentMatchesLocation } from '../../util/url';
import RequestState from '../../types/RequestState';

@observer
class CategoryPage extends Component {
  constructor(props) {
    super(props);

    this.yotpoTimeout = null;
    this.ensureCorrectActiveCategorySet();

    this.state = {
      productCount: 0,
    };

    this.productListRef = createRef();
  }

  componentDidMount() {
    // Another component from the previous DOM may fire its componentWillUnmount
    // and clear the category in between the initial getDerivedStateFromProps
    // and componentDidMount. To compensate for that, check whether the category
    // needs to be loaded again.
    this.ensureCorrectActiveCategorySet();
  }

  componentDidUpdate() {
    const { configStore } = this.props;
    this.ensureCorrectActiveCategorySet();
    if (configStore.integrations.yotpo.enabled && window && window.yotpo) {
      this.yotpoTimeout = setTimeout(() => window.yotpo.refreshWidgets(), 800);
    }
  }

  componentWillUnmount() {
    const { categoryStore, match } = this.props;
    // Clean-up active category, if it was set by this component
    if (
      categoryStore.activeCategory &&
      categoryStore.activeCategory.id === Number(match.params.id)
    ) {
      categoryStore.setActiveCategory(null);
    }

    this.yotpoTimeout && clearTimeout(this.yotpoTimeout);
  }

  updateProductCount = (count) => {
    if (count !== this.state.productCount) {
      this.setState({
        productCount: count,
      });
    }
  };

  ensureCorrectActiveCategorySet = () => {
    const { categoryStore, match } = this.props;

    if (
      !categoryStore.activeCategory ||
      categoryStore.activeCategory.id !== Number(match.params.id)
    ) {
      this.updateActiveCategory();
    }
  };

  getAdParams = () => {
    const { categoryStore } = this.props;

    return categoryStore.activeCategory
      ? { category: categoryStore.activeCategory.id }
      : null;
  };

  shouldHaveAds = () => {
    const { adStore } = this.props;
    return adStore.categoryPageAds.length > 0;
  };

  shouldHaveMainAds = () => {
    const { adStore } = this.props;
    return adStore.categoryPageAds.indexOf(AdZones.MAIN_BANNER) !== -1;
  };

  shouldHaveSidebarAds = () => {
    const { adStore } = this.props;
    return adStore.categoryPageAds.indexOf(AdZones.SIDEBAR_BANNER) !== -1;
  };

  shouldHavePopup = () => {
    const { adStore } = this.props;
    return adStore.categoryPageAds.indexOf(AdZones.POPUP_CONTENT) !== -1;
  };

  maybeLoadAds = () => {
    const { adStore } = this.props;

    if (this.shouldHaveAds()) {
      adStore
        .loadAds(this.getAdParams(), '/category')
        .catch((e) => console.error(e));
    }
  };

  updateActiveCategory = () => {
    const { categoryStore, uiStore, match } = this.props;
    categoryStore.setActiveCategory(match.params.id);

    // If active category did not get set, return 404 to user, because it means it was not found.
    if (!categoryStore.activeCategory) {
      categoryStore.setSingleStateError(match.params.id, {
        response: { status: 404 },
      });
      return;
    }

    if (
      categoryStore.categoryStates.get(match.params.id) !==
        RequestState.LOADING &&
      categoryStore.activeCategory.description_html === null
    ) {
      categoryStore
        .loadCategory(match.params.id)
        .then(() => {
          categoryStore.activeCategory?.name &&
            uiStore.placeholders.productList.setHeight(
              categoryStore.activeCategory.name
            );
        })
        .catch((e) => {
          console.error(e);
        });
    }
    this.maybeLoadAds();
  };

  getBreadcrumbsPath = () => {
    const { categoryStore, routeService } = this.props;

    if (!categoryStore.activeCategory) {
      return [];
    }

    return routeService.transformBreadCrumbs(
      categoryStore.activeCategory.breadcrumbs
    );
  };

  pdfCatalogEnabled = () => {
    const { accountStore, configStore } = this.props;
    const { showPdfButton } = configStore.productList;

    if (showPdfButton === '0') {
      return false;
    }
    if (
      showPdfButton === '1' ||
      (showPdfButton === '2' && accountStore.loggedIn)
    ) {
      return true;
    }
    return showPdfButton === '3' && accountStore.isRetailer;
  };

  renderProductPdfButton = () => {
    const { categoryStore, configStore } = this.props;
    const { productCount } = this.state;

    const isMainCategory = categoryStore.activeCategory.ancestors.length === 0;
    let requireEmail = true;
    const children = this.getChildren();
    const hasChildren = children.length > 0;

    const hasProductList =
      !isMainCategory ||
      (isMainCategory &&
        (configStore.mainCategoryPage.showMainProductList || !hasChildren));

    if (
      hasProductList &&
      productCount <= configStore.productList.productCountForEmail
    ) {
      requireEmail = false;
    }

    return (
      <ProductPdf
        activeId={categoryStore.activeCategory.id.toString()}
        pdfType={ProductPdfType.CATEGORY}
        requireEmail={requireEmail}
      />
    );
  };

  getChildren = () => {
    const { categoryStore, sectionStore } = this.props;
    return sectionStore.activeSection
      ? categoryStore.activeCategory.getNonBlockedChildren(
          sectionStore.activeSection.blockedCategoriesDict
        )
      : categoryStore.activeCategory.children;
  };

  isMainCategory = () => {
    const { categoryStore } = this.props;
    return categoryStore.activeCategory.ancestors.length === 0;
  };

  getCategoryNavigation = () => {
    const { configStore, uiStore } = this.props;

    const children = this.getChildren();
    const hasChildren = children.length > 0;

    /* no navigation */
    if (
      (!configStore.mainCategoryPage.showCategoryGrid &&
        !configStore.navigation.common.showCategorySlider) ||
      !hasChildren
    ) {
      return null;
    }

    /* category grid */
    if (
      configStore.mainCategoryPage.showCategoryGrid &&
      this.isMainCategory() &&
      !uiStore.isMobile
    ) {
      return <CategoryGrid categories={children} />;
    }

    /* category slider */
    if (configStore.navigation.common.showCategorySlider) {
      return <NavigationCategorySlider categories={children} />;
    }

    /* fallback */
    return null;
  };

  getCategoryContent = () => {
    const listId = 'category_list';
    const listName = 'Category List';
    const { match, sectionStore, categoryStore, configStore } = this.props;

    const fixedParams = {
      categories: [match.params.id],
      cross_categories: [],
    };
    if (sectionStore.activeSection) {
      fixedParams.sections = [sectionStore.activeSection.id];
    }
    const showCategoryFilter =
      categoryStore.activeCategory.show_category_filter;

    if (
      configStore.suitableProducts.enabledOnCategories.indexOf(
        categoryStore.activeCategory.id
      ) !== -1
    ) {
      return (
        <SuitableProductFinder
          categoryId={Number(match.params.id)}
          listId={listId}
          listName={listName}
        />
      );
    } else if (this.isMainCategory()) {
      let hasChildren;
      const children = this.getChildren();
      hasChildren = children.length > 0;

      let maybeProductList;
      if (configStore.mainCategoryPage.showMainProductList || !hasChildren) {
        maybeProductList = (
          <MainProductList
            productListRef={this.productListRef}
            fixedParams={fixedParams}
            listId={listId}
            name={listName}
            showCategoryFilter={showCategoryFilter}
            updateProductCount={this.updateProductCount}
          />
        );
      } else if (configStore.mainCategoryPage.showProductTabs) {
        maybeProductList = (
          <ProductTabs
            productListRef={this.productListRef}
            searchParams={{
              ...fixedParams,
            }}
          />
        );
      }

      return maybeProductList;
    } else {
      return (
        <MainProductList
          productListRef={this.productListRef}
          fixedParams={fixedParams}
          listId={listId}
          name={listName}
          showCategoryFilter={showCategoryFilter}
          updateProductCount={this.updateProductCount}
        />
      );
    }
  };

  renderLoadedContent = (mainBannerWithinContent, mainBanner) => {
    const { categoryStore, configStore, uiStore, routeService } = this.props;

    if (!categoryStore.activeCategory) {
      return null;
    }

    if (this.productListRef?.current) {
      uiStore.placeholders.productList.updateHeight({
        page: categoryStore.activeCategory.name,
        height: this.productListRef?.current.clientHeight,
      });
    }

    return (
      <>
        {!categoryStore.activeCategory.isIndexableByRobots && (
          <RobotsMeta noindex nofollow />
        )}
        <SEOTitle
          title={
            categoryStore.activeCategory.SEO_title ||
            categoryStore.activeCategory.name
          }
          noSuffix={!!categoryStore.activeCategory.SEO_title}
        />
        {categoryStore.activeCategory.SEO_description && (
          <SEODescription
            content={categoryStore.activeCategory.SEO_description}
          />
        )}
        <CanonicalLink
          path={routeService.getCanonicalCategoryPath(
            categoryStore.activeCategory
          )}
        />
        {mainBannerWithinContent && mainBanner}
        <HelperNavigation breadcrumbsPath={this.getBreadcrumbsPath()} />
        <PageTitle>{categoryStore.activeCategory.name}</PageTitle>
        {categoryStore.activeCategory.description_html && (
          <div className="CategoryPage__category-content">
            <WysiwygContent
              html={categoryStore.activeCategory.description_html}
            />
          </div>
        )}
        {this.getCategoryNavigation()}
        {this.pdfCatalogEnabled() && this.renderProductPdfButton()}
        {this.getCategoryContent()}
        <div className="CategoryPage__category-footer-content">
          <WysiwygContent
            html={categoryStore.activeCategory.footer_description_html}
          />
        </div>
        {categoryStore.activeCategory.children.length > 0 &&
          configStore.navigation.common.showCategoryList && (
            <NavigationSimpleCategoryList
              categories={categoryStore.activeCategory.children}
            />
          )}
        {this.shouldHavePopup() && (
          <ContentPopups searchParams={this.getAdParams()} />
        )}
      </>
    );
  };

  /* TODO The component requires some refactoring.
   *   Maybe the logic of what listing / content could be defined in the backend ex. per category.
   *   Also some configurations should be renamed for better description. */

  render() {
    const {
      configStore,
      categoryStore,
      routeService,
      sectionStore,
      uiStore,
      match,
      location,
    } = this.props;

    if (categoryStore.activeCategory === null) {
      return <Redirect to={routeService.getPath(Paths.NotFoundPage)} />;
    }

    const maybeRedirectTo = `${routeService.getCategoryPath(
      categoryStore.activeCategory
    )}${location.search}${location.hash}`;

    // Fix the URL when section store is missing activeSection
    if (configStore.activateSections && sectionStore.activeSection === null) {
      // Unless the category has no section
      if (
        routeService.getCategoryPath(categoryStore.activeCategory) !==
        categoryStore.activeCategory.path
      ) {
        return <Redirect to={maybeRedirectTo} />;
      }
    }

    // Fix the URL if it contains unexpected parts
    if (
      categoryStore.activeCategory &&
      String(categoryStore.activeCategory.id) === match.params.id &&
      !pathFragmentMatchesLocation(
        categoryStore.activeCategory.path,
        location.pathname
      )
    ) {
      return <Redirect to={maybeRedirectTo} />;
    }

    const id = match.params.id;

    const mainBanner = this.shouldHaveMainAds() ? (
      <MainBanner searchParams={this.getAdParams()} />
    ) : null;
    const mainBannerWithinContent = configStore.banner.mainBanner.withinContent;

    return (
      <Page name={CommonPage.CATEGORY} className="CategoryPage">
        <ScrollReset key={id} />
        {!mainBannerWithinContent && mainBanner}
        <Container className="CategoryPage__container">
          <SidebarLayout
            sidebarPlaceHolder={!uiStore.isMobile ? <SidebarSkeleton /> : null}
            sidebar={
              <DefaultSidebar
                bannerSearchParams={
                  this.shouldHaveSidebarAds() ? this.getAdParams() : null
                }
              />
            }
            content={
              <ContentForState
                state={categoryStore.categoryStates.get(id)}
                error={categoryStore.lastError}
                forPlaceHolder={<PageSkeleton />}
                forLoaded={() =>
                  this.renderLoadedContent(mainBannerWithinContent, mainBanner)
                }
              />
            }
          />
        </Container>
      </Page>
    );
  }
}

CategoryPage.propTypes = {
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  adStore: modelOf(AdStore),
  configStore: modelOf(ConfigStore),
  categoryStore: modelOf(CategoryStore),
  sectionStore: modelOf(SectionStore),
  uiStore: modelOf(UIStore),
  match: RouterPropTypes.match.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default inject(
  'adStore',
  'configStore',
  'categoryStore',
  'routeService',
  'sectionStore',
  'uiStore'
)(withRouter(CategoryPage));
