import { applyPatch, flow, getEnv, getRoot, types } from 'mobx-state-tree';

import CurrentOrder from '../models/cart/CurrentOrder';
import Error from '../models/Error';
import Order from '../models/order/Order';
import OrdersPaginator from '../models/order/OrdersPaginator';
import StatefulStore from '../models/StatefulStore';
import RequestState, { RequestStateType } from '../types/RequestState';
import createMSTPatch from '../util/createMSTPatch';
import { createErrorModel } from '../util/error';
import { paramsToQueryIdentifier } from '../util/query';

const OrderStore = StatefulStore.named('OrderStore')
  .props({
    orders: types.optional(types.map(Order), {}),
    orderStates: types.optional(types.map(RequestStateType), {}),
    orderQueryResults: types.optional(types.map(OrdersPaginator), {}),
    orderQueryStates: types.optional(types.map(RequestStateType), {}),
    currentOrder: types.maybeNull(CurrentOrder),
    currentOrderState: types.optional(RequestStateType, RequestState.NONE),
    lastCurrentOrderError: types.maybeNull(Error),
  })
  .views((self) => {
    return {
      get ordersArray() {
        return Array.from(self.orders, ([key, value]) => value);
      },
    };
  })
  .actions((self) => {
    const getLang = () => getRoot(self).languageStore.activeLanguage.code;
    const ifShoppingCenter = () =>
      getRoot(self).configStore.siteConfig.isShoppingCenter;

    const baseApi = (endpoint) =>
      ifShoppingCenter()
        ? `shopping-center/instant-shopping/order/${endpoint}`
        : `current-order/${endpoint}`;

    return {
      loadOrder: flow(function* loadOrder(id, hash) {
        if (self.orderStates.get(id) === RequestState.LOADED) {
          return;
        }

        self.orderStates.set(id, RequestState.LOADING);

        try {
          const order = yield getEnv(self).apiWrapper.request(
            `my-account/order/${id}`,
            {
              params: {
                hash,
              },
            }
          );
          self.orders.set(id, order);
        } catch (e) {
          self.setError(e);
          self.orderStates.set(id, RequestState.ERROR);
          throw e;
        }

        self.orderStates.set(id, RequestState.LOADED);
      }),
      loadOrders: flow(function* loadOrders(params) {
        const queryIdentifier = paramsToQueryIdentifier(params);
        self.setLoading(true);
        self.orderQueryStates.set(queryIdentifier, RequestState.LOADING);

        try {
          const orderPaginator = yield getEnv(self).apiWrapper.request(
            `my-account/orders`,
            {
              params,
            }
          );

          const orders = orderPaginator.data;
          // Update individual orders and statuses
          orders.forEach((order) => {
            const oldOrder = self.orders.get(order.id);
            if (oldOrder) {
              const patch = createMSTPatch(oldOrder, order);
              applyPatch(oldOrder, patch);
            } else {
              self.orders.set(order.id, order);
            }
            self.orderStates.set(order.id, RequestState.LOADED);
          });
          // Set current query results
          self.orderQueryResults.set(queryIdentifier, {
            ...orderPaginator,
            data: orders.map((order) => order.id),
          });
        } catch (e) {
          self.setError(e);
          self.orderQueryStates.set(queryIdentifier, RequestState.ERROR);
          throw e;
        }

        self.orderQueryStates.set(queryIdentifier, RequestState.LOADED);
        self.setLoading(false);
      }),
      loadCurrentOrderPostPurchase: flow(function* loadCurrentOrder(
        params = null
      ) {
        self.currentOrderState = RequestState.LOADING;

        if (params) {
          params.lang = getLang();
        }

        try {
          self.currentOrder = yield getEnv(self).apiWrapper.request(
            `${baseApi('success')}`,
            {
              params,
            },
            { active_section: null }
          );
        } catch (error) {
          self.lastCurrentOrderError = createErrorModel(error);
          self.currentOrderState = RequestState.ERROR;
          throw error;
        }

        self.currentOrderState = RequestState.LOADED;
      }),
    };
  });

export default OrderStore;
