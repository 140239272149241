import classNames from 'classnames';
import { get } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Alert, Button, Form } from 'reactstrap';

import Analytics from '../../../analytics/Analytics';
import globalTranslations from '../../../i18n/globalTranslations';
import { createForm } from '../../../models/Form';
import { createFormField } from '../../../models/FormField';
import { modelOf } from '../../../prop-types';
import ApiWrapper from '../../../services/ApiWrapper';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import ValidationStatus, {
  errorMap,
} from '../../../types/form/ValidationStatus';
import RequestState from '../../../types/RequestState';
import { createErrorModel } from '../../../util/error';
import { isEmail, notBlank } from '../../../util/formValidators';
import Icon from '../../common/Icon';
import FormGroupField from '../../form/FormGroupField';
import RecaptchaLegalBanner from '../../legal/RecaptchaLegalBanner';
import ErrorHandler from '../../loader/ErrorHandler';
import UnexpectedError from '../../loader/UnexpectedError';
import PrivacyStatementLink from '../../privacy-statement/PrivacyStatementLink';

@observer
class NewsletterSubscriptionForm extends Component {
  constructor(props) {
    super(props);
    const { configStore } = this.props;

    this.form = null;
    this.formName = 'NewsletterSubscriptionForm';
    this.state = {
      error: null,
      status: ValidationStatus.NONE,
      requestState: RequestState.NONE,
      // This is used to know, if we've made a new request so we can re-animate errors
      requestId: 0,
    };

    const fields = {
      email: createFormField({}, [
        notBlank(<FormattedMessage {...globalTranslations.emailTitle} />),
        isEmail(<FormattedMessage {...globalTranslations.emailTitle} />),
      ]),
    };

    if (configStore.newsletter.requireName) {
      fields.firstName = createFormField({}, [
        notBlank(<FormattedMessage {...globalTranslations.firstNameTitle} />),
      ]);
      fields.lastName = createFormField({}, [
        notBlank(<FormattedMessage {...globalTranslations.lastNameTitle} />),
      ]);
    }

    this.form = createForm(fields);
  }

  handleSubmit = async (e) => {
    const { configStore, uiStore, analytics, apiWrapper } = this.props;

    e.preventDefault();
    this.form.validate();

    if (this.form.valid) {
      this.setState({ requestState: RequestState.LOADING });
      await uiStore.recaptchaValidation.getCaptchaToken();

      if (uiStore.recaptchaValidation.isValid) {
        const values = this.form.getFieldValues();
        values.recaptcha = uiStore.recaptchaValidation.token;

        apiWrapper
          .apiAxios()
          .post('newsletter-subscriptions', values)
          .then(() => {
            this.setState({
              status: ValidationStatus.SUCCESS,
              requestState: RequestState.LOADED,
            });
            configStore.analytics.ga4.enabled &&
              analytics.joinGroup('Newsletter');
          })
          .catch((error) => {
            const status = error.response ? error.response.status : 500;

            this.setState({
              requestState: RequestState.ERROR,
              status: errorMap[status],
              requestId: this.state.requestId + 1,
              error,
            });
          });
      }

      configStore.recaptcha.enabled &&
        uiStore.recaptchaValidation.resetCaptchaToken();
    }
  };

  getField = (name, message) => {
    const { compact } = this.props;
    return (
      <div
        className={`NewsletterSubscriptionForm__field NewsletterSubscriptionForm__field--${name}`}
      >
        <FormGroupField
          field={this.form.fields.get(name)}
          label={<FormattedMessage {...message} />}
          formName={this.formName}
          fieldName={name}
          hideLabel={compact}
          placeholder={compact && this.props.intl.formatMessage(message)}
        />
      </div>
    );
  };

  getSubscribeButtonContent = () => {
    const { compact } = this.props;

    if (compact) {
      return (
        <FormattedMessage
          id="newsletter.subscribeCompact"
          defaultMessage="Subscribe"
        />
      );
    }

    return (
      <FormattedMessage id="newsletter.subscribe" defaultMessage="Subscribe" />
    );
  };

  renderForm = () => {
    const { configStore, uiStore, compact, intl } = this.props;
    const { requestState } = this.state;

    const isLoading = requestState === RequestState.LOADING;

    return (
      <Form name={this.for} noValidate onSubmit={this.handleSubmit}>
        {configStore.newsletter.requireName && (
          <div className="NewsletterSubscriptionForm__row">
            {this.getField('firstName', globalTranslations.firstNameTitle)}
            {this.getField('lastName', globalTranslations.lastNameTitle)}
          </div>
        )}
        <div className="NewsletterSubscriptionForm__row">
          {this.getField('email', globalTranslations.emailTitle)}
          <Button
            className="NewsletterSubscriptionForm__submit"
            color="primary"
            disabled={
              this.form.valid === false ||
              uiStore.recaptchaValidation.isValid === false ||
              isLoading
            }
            type="submit"
            aria-label={
              compact
                ? intl.formatMessage({
                    id: 'newsletter.subscribeCompact',
                    defaultMessage: 'Subscribe',
                  })
                : intl.formatMessage({
                    id: 'newsletter.subscribe',
                    defaultMessage: 'Subscribe',
                  })
            }
          >
            {isLoading ? (
              <Icon name="circle-o-notch" spin={isLoading} />
            ) : (
              this.getSubscribeButtonContent()
            )}
          </Button>
        </div>
      </Form>
    );
  };

  getErrorMessages = () => {
    const { error } = this.state;
    return get(error, 'response.data.messages');
  };

  render() {
    const { compact, configStore } = this.props;
    const { status, requestId, error } = this.state;
    let message;
    const errors = this.getErrorMessages();

    switch (status) {
      case ValidationStatus.SUCCESS:
        message = (
          <Alert color="success">
            <FormattedMessage
              id="newsletter.status.SUCCESS"
              defaultMessage="You have been added to our e-mail list. Thank you for subscribing!"
            />
          </Alert>
        );
        break;
      case ValidationStatus.VALIDATION_ERROR:
        message = (
          <>
            {error && get(error, 'response.status') !== 422 && (
              <ErrorHandler error={createErrorModel(error)} />
            )}
            {errors && (
              <Alert color="danger" key={requestId}>
                {errors.map((errorMessage) => (
                  <div key={errorMessage}>{errorMessage}</div>
                ))}
              </Alert>
            )}
          </>
        );
        break;
      case ValidationStatus.CONFLICT:
        message = (
          <Alert color="danger">
            <FormattedMessage
              id="newsletter.status.EMAIL_EXISTS"
              defaultMessage="The e-mail address you entered is already in our e-mail list."
            />
          </Alert>
        );
        break;
      case ValidationStatus.UNEXPECTED_ERROR:
        message = <UnexpectedError />;
        break;
      case ValidationStatus.NONE:
      default:
        message = null;
        break;
    }

    return (
      <div
        className={classNames('NewsletterSubscriptionForm', {
          'NewsletterSubscriptionForm--compact': compact,
        })}
      >
        {message}
        {status !== ValidationStatus.SUCCESS && this.renderForm()}
        {configStore.recaptcha.enabled && <RecaptchaLegalBanner />}
        <div className="NewsletterSubscriptionForm__privacy-statement">
          <FormattedMessage
            id="newsletter.subscriberAgreesTo"
            defaultMessage="By subscribing you agree to"
          />{' '}
          <PrivacyStatementLink>
            <FormattedMessage
              id="privacyStatement.subscriberAgreesLink"
              defaultMessage="the Privacy Statement"
            />
          </PrivacyStatementLink>
          .
        </div>
      </div>
    );
  }
}

NewsletterSubscriptionForm.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
  compact: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default inject(
  'analytics',
  'apiWrapper',
  'configStore',
  'uiStore'
)(injectIntl(NewsletterSubscriptionForm));
