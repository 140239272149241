import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import useProductSchema from '../../../../hooks/schema/useProductSchema';
import Product from '../../../../models/Product';
import { modelOf } from '../../../../prop-types';
import { LocationContextPropType } from '../../../../services/LocationContext';
import RouteService from '../../../../services/RouteService';
import AccountStore from '../../../../store/AccountStore';
import ConfigStore from '../../../../store/ConfigStore';
import CountryStore from '../../../../store/CountryStore';
import CurrencyStore from '../../../../store/CurrencyStore';
import ProductPropertyType from '../../../../types/ProductPropertyType';
import SEOSchema from '../../../head/SEOSchema';

const supportedSchemaProperties = [
  ProductPropertyType.SIZE,
  ProductPropertyType.COLOR,
];

const ProductGroupSchema = ({
  accountStore,
  configStore,
  countryStore,
  currencyStore,
  routeService,
  locationContext,
  baseSchema,
  product,
}) => {
  const [getProductSchema] = useProductSchema({
    accountStore,
    configStore,
    countryStore,
    currencyStore,
    routeService,
    locationContext,
  });

  const productGroupSchema = baseSchema;
  const url = `${locationContext.protocol}//${
    locationContext.host
  }${routeService.getProductPath(product)}`;

  productGroupSchema['@type'] = 'ProductGroup';
  productGroupSchema.name = product.multiproduct_title || product.name;
  productGroupSchema.productGroupID = product.id;
  productGroupSchema.url = url;

  const shouldShowModel = () => {
    return (
      configStore.productPage.showMultiProductModel &&
      configStore.product.showModel
    );
  };

  if (shouldShowModel()) {
    productGroupSchema.model = product.model;
  }

  const supportedProperties = {};
  product.multi.properties.forEach((property) => {
    if (supportedSchemaProperties.includes(property.type)) {
      supportedProperties[property.id] = property.type;
    }
  });

  const propertyValues = Object.values(supportedProperties);

  const getProductVariants = useCallback(() => {
    return product.multi.children.map((childProduct) => {
      const productProperties = {};

      const productSchema = getProductSchema(childProduct);

      productSchema.offers.url = productSchema.offers.url + childProduct.id;

      propertyValues.length &&
        childProduct.extra_properties.forEach((property) => {
          const supportedProperty = supportedProperties[property.id];
          if (supportedProperty) {
            const propertyType = supportedProperty.toLowerCase();
            productProperties[propertyType] = property.value_name;
          }
        });

      return {
        ...productSchema,
        ...productProperties,
      };
    });
  }, [supportedProperties]);

  productGroupSchema.hasVariant = getProductVariants();

  if (propertyValues.length) {
    productGroupSchema.variesBy = propertyValues.map(
      (property) => `https://schema.org/${property.toLowerCase()}`
    );
  }

  return <SEOSchema data={productGroupSchema} />;
};

ProductGroupSchema.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  countryStore: modelOf(CountryStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  locationContext: LocationContextPropType.isRequired,
  product: modelOf(Product).isRequired,
  baseSchema: PropTypes.object.isRequired,
};

export default inject(
  'accountStore',
  'configStore',
  'countryStore',
  'currencyStore',
  'routeService',
  'locationContext'
)(observer(ProductGroupSchema));
