import { types } from 'mobx-state-tree';

import Paths from '../types/Paths';
import generatePath from '../util/generatePath';
import ManufacturerIntegration from './ManufacturerIntegration';

const Manufacturer = types
  .model('Manufacturer', {
    description_html: types.maybeNull(types.string),
    footer_description_html: types.maybeNull(types.string),
    has_products: types.maybeNull(types.boolean),
    id: types.identifier,
    image: types.maybeNull(types.string),
    integrations: types.array(ManufacturerIntegration),
    name: types.string,
    section_ids: types.array(types.integer),
    SEO_description: types.maybeNull(types.string),
    SEO_title: types.maybeNull(types.string),
    slug: types.string,
  })
  .views((self) => {
    return {
      get breadcrumbs() {
        return {
          text: self.name,
          url: self.path,
        };
      },
      get descriptionContent() {
        return (
          self.description_html ||
          self.SEO_description ||
          self.footer_description_html ||
          null
        );
      },
      get path() {
        return generatePath(Paths.Manufacturer, {
          slug: self.slug || self.name || self.id,
          id: self.id,
        });
      },
      belongsToSection: (sectionId) =>
        self.section_ids.indexOf(sectionId) !== -1,
    };
  });

export default Manufacturer;
