import { keyBy } from 'lodash';
import { flow, getEnv, getRoot, types } from 'mobx-state-tree';

import Manufacturer from '../models/Manufacturer';
import StatefulStore from '../models/StatefulStore';
import RequestState, { RequestStateType } from '../types/RequestState';

const ManufacturerStore = StatefulStore.named('ManufacturerStore')
  .props({
    manufacturers: types.optional(types.map(Manufacturer), {}),
    manufacturerContentStates: types.optional(types.map(RequestStateType), {}),
    loadedWithContent: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get manufacturersArray() {
        return Array.from(self.manufacturers.values()).sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      },
      get manufacturersWithProducts() {
        return self.manufacturersArray.filter(
          (manufacturer) => manufacturer.has_products
        );
      },
      get hasManufacturers() {
        return self.manufacturersArray.length > 0;
      },
    };
  })
  .actions((self) => {
    const ifShoppingCenter = () =>
      getRoot(self).configStore
        ? getRoot(self).configStore.siteConfig.isShoppingCenter
        : false;

    const baseApi = (endpoint) =>
      ifShoppingCenter() ? `shopping-center/${endpoint}` : `${endpoint}`;

    return {
      setup: (manufacturers) => {
        self.manufacturers = keyBy(manufacturers, 'id');
        self.setLoading(false);
      },
      loadManufacturer: flow(function* loadManufacturer(id) {
        self.manufacturerContentStates.set(id, RequestState.LOADING);
        try {
          const manufacturer = yield getEnv(self).apiWrapper.request(
            `${baseApi('manufacturers')}/${id}`
          );
          self.manufacturers.set(manufacturer.id, manufacturer);
          self.manufacturerContentStates.set(id, RequestState.LOADED);
        } catch (e) {
          self.setError(e);
          self.manufacturerContentStates.set(id, RequestState.ERROR);
          throw e;
        }
      }),
      loadManufacturers: flow(function* loadManufacturers({
        excludeContent = true,
      }) {
        self.setLoading(true);
        try {
          const manufacturers = yield getEnv(self).apiWrapper.request(
            baseApi('manufacturers'),
            {
              params: {
                excludeContent: Number(excludeContent),
              },
            }
          );

          manufacturers.forEach((manufacturer) => {
            const manufacturerHasContent =
              manufacturer.description_html ||
              manufacturer.SEO_description ||
              manufacturer.footer_description_html ||
              null;

            const loadedManufacturer = self.manufacturers.get(manufacturer.id);
            if (loadedManufacturer && !manufacturerHasContent) {
              return;
            } else {
              self.manufacturers.set(manufacturer.id, manufacturer);
            }
          });

          if (excludeContent === false && Array.isArray(manufacturers)) {
            self.loadedWithContent = true;
            manufacturers.forEach((manufacturer) => {
              self.manufacturerContentStates.set(
                manufacturer.id,
                RequestState.LOADED
              );
            });
          }
        } catch (e) {
          self.setError(e);
          throw e;
        }

        self.setLoading(false);
      }),
    };
  });

export default ManufacturerStore;
