import { types } from 'mobx-state-tree';
import ProductTypeClass, { ProductType } from '../../types/ProductTypeClass';

const OrderProduct = types
  .model('OrderProduct', {
    id: types.identifier,
    actual_code: types.string,
    product_id: types.string,
    name: types.string,
    quantity_ordered: types.number,
    quantity_delivered: types.number,
    package_size: types.maybeNull(types.number),
    stock_unit: types.string,
    starting_unit_price: types.maybeNull(types.string),
    final_unit_price: types.string,
    total_price: types.string,
    type: ProductType,
    reference: types.string,
    warranty_in_months: types.number,
    package_info: types.string,
    is_free_of_charge: types.boolean,
    variations: types.array(types.string),
    image_url: types.maybeNull(types.string),
    product_url: types.maybeNull(types.string),
    tax_rate: types.maybeNull(types.string),
    recurring_order_cycle_type: types.maybeNull(types.string),
    recurring_order_custom_cycle: types.maybeNull(types.number),
  })
  .views((self) => ({
    get isPhysicalProduct() {
      return self.type === ProductTypeClass.PHYSICAL;
    },
    get productCode() {
      return self.actual_code;
    },
  }));

export default OrderProduct;
