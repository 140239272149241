import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button } from 'reactstrap';

import globalTranslations from '../../../i18n/globalTranslations';
import Order from '../../../models/order/Order';
import { modelOf } from '../../../prop-types';
import CartStore from '../../../store/CartStore';
import RequestState from '../../../types/RequestState';

import styles from '../../../styles/_exports.scss';

const OrderCloneButton = ({ cartStore, order, intl }) => {
  const handleAddFromOrder = () => {
    if (cartStore.state === RequestState.LOADING) {
      return;
    }

    if (cartStore.cart) {
      cartStore.addFromOrder(order.id).catch((e) => {
        console.error(e);
      });
    }
  };

  return (
    <Button
      className="OrderCloneButton"
      color="primary"
      disabled={cartStore.state !== RequestState.LOADED}
      onClick={() =>
        window.confirm(
          intl.formatMessage(globalTranslations.confirmAddToCartTitle)
        ) && handleAddFromOrder()
      }
      style={{
        margin: `${styles['spacer']} 0 ${styles['spacer-8']} 0`,
        overflow: `hidden`,
        textOverflow: `ellipsis`,
        whiteSpace: 'nowrap',
      }}
    >
      <FormattedMessage {...globalTranslations.cloneOrder} />
    </Button>
  );
};

OrderCloneButton.propTypes = {
  cartStore: modelOf(CartStore).isRequired,
  order: modelOf(Order),
  intl: intlShape.isRequired,
};

export default inject('cartStore')(injectIntl(observer(OrderCloneButton)));
