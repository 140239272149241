import { useCallback } from 'react';
import LinkType from '../../types/LinkType';
import {
  domainMatchesUrl,
  stripHostIfMatches,
  transformExternalLink,
  transformInternalLink,
} from '../../util/url';

const useMaybeExternalLink = ({ path, host, type = '' }) => {
  let childPath = path;
  let linkTarget = '';
  let linkRel = '';

  if (type && type === LinkType.INTERNAL_LINK) {
    childPath = transformInternalLink(path);
  }

  if (type && type === LinkType.PAGE_LINK) {
    childPath = stripHostIfMatches(path, host);
  }

  if (
    type &&
    type === LinkType.EXTERNAL_LINK &&
    !!domainMatchesUrl(host, path)
  ) {
    childPath = transformExternalLink(path);
    linkTarget = '_self';
  }

  if (
    type &&
    type === LinkType.EXTERNAL_LINK &&
    !!!domainMatchesUrl(host, path)
  ) {
    linkTarget = '_blank';
    linkRel = 'noopener';
  }

  const getLinkProps = useCallback(() => {
    let linkProps = {};

    if (linkTarget) {
      linkProps = {
        ...linkProps,
        target: linkTarget,
      };
    }

    if (linkRel) {
      linkProps = {
        ...linkProps,
        rel: linkRel,
      };
    }

    return linkProps;
  }, []);

  return [childPath, getLinkProps];
};

export default useMaybeExternalLink;
