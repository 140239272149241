import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { matchPath, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import { Button, Col, Container, Row } from 'reactstrap';

import Analytics from '../../../analytics/Analytics';
import useNavigationEntityConverter from '../../../hooks/navigation/useNavigationEntityConverter';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import RouteService from '../../../services/RouteService';
import AccountStore from '../../../store/AccountStore';
import CampaignCodeStore from '../../../store/CampaignCodeStore';
import CartStore from '../../../store/CartStore';
import ConfigStore from '../../../store/ConfigStore';
import CouponStore from '../../../store/CouponStore';
import ManufacturerStore from '../../../store/ManufacturerStore';
import MenuStore from '../../../store/MenuStore';
import SectionStore from '../../../store/SectionStore';
import UIStore from '../../../store/UIStore';
import AccountModalTab from '../../../types/AccountModalTab';
import MobileNavigationTab from '../../../types/MobileNavigationTab';
import Paths from '../../../types/Paths';
import RequestState from '../../../types/RequestState';
import SectionIntegrationType from '../../../types/SectionIntegrationType';
import FooterBanner from '../../ad/FooterBanner';
import Icon from '../../common/Icon';
import NavigationAssistButton from '../../common/NavigationAssistButton';
import SSRAwareLazyLoad from '../../common/SSRAwareLazyLoad';
import CouponText from '../../coupon/CouponText';
import CollapsibleMenu from '../../menu/CollapsibleMenu';
import MenuItemList from '../../menu/MenuItemList';
import NewsletterSubscriptionForm from '../../newsletter/NewsletterSubscriptionForm';
import Price from '../../product/Price';
import FooterBackToTopButton from '../FooterBackToTopButton';
import FooterCartExpirationBanner from '../FooterCartExpirationBar';
import FooterColumn from '../FooterColumn';
import FooterCopyright from '../FooterCopyright';
import FooterManufacturers from '../FooterManufacturers';
import FooterPaymentModules from '../FooterPaymentModules';

const INFO_SEND_EMAIL_ID = 'infoSendEmail';
const INFO_CALL_PHONE_ID = 'infoCallPhone';
const BRANDS_ID = 'brands';
const SINGLE_ID = 'single';

const CommonFooter = ({
  accountStore,
  campaignCodeStore,
  cartStore,
  configStore,
  couponStore,
  manufacturerStore,
  menuStore,
  routeService,
  sectionStore,
  uiStore,
  analytics,
  intl,
  location,
}) => {
  useEffect(() => {
    if (
      !manufacturerStore.loadedWithContent &&
      manufacturerStore.state === RequestState.NONE
    ) {
      /* Use this as the common load function for all manufacturer locations */
      manufacturerStore.loadManufacturers({ excludeContent: true });
    }
  }, []);

  const shouldHideMixedContent = () => {
    return (
      configStore.integrations.zoovu.enabled &&
      sectionStore.activeSection &&
      sectionStore.activeSection.integration_type ===
        SectionIntegrationType.ZOOVU
    );
  };

  const getMenuEntities = () => {
    // TODO: This will have a separate configuration for visibility.
    if (!configStore.siteConfig.isStore) {
      return null;
    }

    return useNavigationEntityConverter({ entities: menuStore.menus });
  };

  const getStaticDesktopColumnEntities = () => {
    const children = [];

    if (accountStore.isRetailer) {
      children.push({
        id: 'shopCustomerProducts',
        label: (
          <FormattedMessage {...globalTranslations.customerProductsTitle} />
        ),
        path: routeService.getPath(Paths.CustomerProducts),
      });
    }

    children.push({
      id: 'shopOnSale',
      label: <FormattedMessage {...globalTranslations.onSaleTitle} />,
      path: routeService.getPath(Paths.OnSale),
    });
    children.push({
      id: 'shopNewProducts',
      label: <FormattedMessage {...globalTranslations.newProductsTitle} />,
      path: routeService.getPath(Paths.NewProducts),
    });

    if (manufacturerStore.hasManufacturers) {
      children.push({
        id: 'shopBrands',
        label: <FormattedMessage {...globalTranslations.brandsTitle} />,
        path: routeService.getPath(Paths.ManufacturerList),
      });
    }

    if (!accountStore.loggedIn && configStore.registration.enabled) {
      children.push({
        id: 'shopRegistration',
        label: (
          <span
            onClick={(e) => {
              e.preventDefault();
              accountStore.accountModal.setTab(AccountModalTab.REGISTER);
              accountStore.accountModal.toggle();
            }}
          >
            <FormattedMessage
              id="account.doYouWantToRegister"
              defaultMessage="Would you like to become a registered customer?"
            />
          </span>
        ),
        path: '#',
      });
    }

    return [
      {
        id: 'shop',
        label: configStore.store.name,
        children,
      },
    ];
  };

  const getSingleLinkEntities = (children) => ({
    id: SINGLE_ID,
    label: <FormattedMessage {...globalTranslations.linkTitle} />,
    children,
  });

  const getStaticLinkEntities = () => {
    const mobileEntities = {
      beforeMenuItems: [],
      afterMenuItems: [],
    };

    if (configStore.newsletter.enabled) {
      mobileEntities.beforeMenuItems.push({
        id: 'infoNewsletter',
        label: (
          <span>
            <FormattedMessage
              {...globalTranslations.newsletterSubscriptionSentence}
            />{' '}
            {couponStore.newsletterCoupon && (
              <span>
                – <CouponText coupon={couponStore.newsletterCoupon} short />
              </span>
            )}
          </span>
        ),
        children: [
          {
            id: 'newsletter',
            label: getNewsLetterForm(),
          },
        ],
      });
    }

    if (configStore.customerService.email.length > 0) {
      mobileEntities.afterMenuItems.push({
        id: INFO_SEND_EMAIL_ID,
        path: `mailto:${configStore.customerService.email}`,
        label: (
          <>
            <FormattedMessage
              {...globalTranslations.infoSendEmail}
              values={{
                email: configStore.customerService.email,
              }}
            />
          </>
        ),
      });
    }

    if (configStore.customerService.phone.length > 0) {
      mobileEntities.afterMenuItems.push({
        id: INFO_CALL_PHONE_ID,
        path: `tel:${configStore.customerService.phone}`,
        label: (
          <>
            <div className="CommonFooter__text-container d-flex flex-column">
              <FormattedMessage
                {...globalTranslations.infoCallPhone}
                values={{
                  phone: configStore.customerService.phone,
                }}
              />
              {configStore.customerService.open.length > 0 && (
                <FormattedMessage
                  {...globalTranslations.isOpen}
                  values={{
                    timespan: configStore.customerService.open,
                  }}
                />
              )}
            </div>
          </>
        ),
      });
    }

    if (configStore.siteConfig.isStore) {
      mobileEntities.afterMenuItems.push({
        id: BRANDS_ID,
        path: routeService.getPath(Paths.ManufacturerList),
        label: (
          <>
            <FormattedMessage {...globalTranslations.ourBrandsTitle} />
          </>
        ),
      });
    }

    if (!configStore.siteConfig.isShoppingCenter) {
      mobileEntities.afterMenuItems.push(getAccountColumnEntities());
    }

    if (!accountStore.isViewOnly && configStore.siteConfig.isWebStore) {
      mobileEntities.afterMenuItems.push({
        id: 'cartContents',
        label: (
          <FormattedMessage {...globalTranslations.proceedToCartSentence} />
        ),
        children: [
          {
            id: 'cartContents',
            label: getCartContents() || (
              <FormattedMessage
                id="cart.loadingCartContents"
                defaultMessage="Loading cart contents"
              />
            ),
          },
        ],
      });
    }

    return mobileEntities;
  };

  const getAccountColumnEntities = () => {
    const entities = {
      id: 'account',
      label: <FormattedMessage {...globalTranslations.myAccountTitle} />,
      children: [],
    };

    if (accountStore.loggedIn) {
      let accountPath = accountStore.isBalanceBuyer
        ? Paths.MyAccountBalanceUser
        : Paths.MyAccount;
      if (accountStore.isBalanceUser) {
        accountPath = Paths.MyAccountBalanceSubUser;
      }

      entities.children.push({
        id: 'accountLogout',
        path: '#',
        label: (
          <span
            onClick={(e) => {
              e.preventDefault();
              analytics.clearCart();
              accountStore.logout();
            }}
          >
            <FormattedMessage {...globalTranslations.accountLogOutSentence} />
          </span>
        ),
      });

      if (configStore.account.enableMyAccount) {
        entities.children.push({
          id: 'accountDetails',
          path: routeService.getPath(accountPath),
          label: (
            <FormattedMessage {...globalTranslations.myCustomerAccountTitle} />
          ),
        });
      }

      if (
        configStore.account.enableMyAccount &&
        !configStore.account.isMoneyTransfer
      ) {
        entities.children.push({
          id: 'accountHistory',
          path: routeService.getPath(Paths.MyAccountOrderList),
          label: (
            <FormattedMessage {...globalTranslations.myOrderHistoryTitle} />
          ),
        });
      }
    } else {
      if (!configStore.account.disableLogin) {
        entities.children.push({
          id: 'accountLogin',
          path: '#',
          label: (
            <span
              onClick={(e) => {
                e.preventDefault();
                accountStore.accountModal.setTab(AccountModalTab.LOGIN);
                accountStore.accountModal.toggle();
              }}
            >
              <FormattedMessage {...globalTranslations.logInSentence} />
            </span>
          ),
        });
        entities.children.push({
          id: 'accountForgotPassword',
          path: '#',
          label: (
            <span
              onClick={(e) => {
                e.preventDefault();
                accountStore.accountModal.setTab(AccountModalTab.LOGIN);
                accountStore.accountModal.toggle();
                accountStore.accountModal.toggleResetPasswordVisible();
              }}
            >
              <FormattedMessage
                id="account.forgotYourPassword"
                defaultMessage="Forgot your password?"
              />
            </span>
          ),
        });
      }
    }

    return entities.children.length > 0 ? entities : {};
  };

  const getCartContents = () => {
    const { checkout } = configStore;

    if (!cartStore.cart) {
      return null;
    }

    const withTax = accountStore.showPricesWithTax;
    const showTaxExcludedInfo = !withTax;

    return (
      <div className="CommonFooter__cart-contents-container">
        <FormattedMessage
          {...globalTranslations.itemCount}
          values={{
            count: cartStore.cart.number_of_products,
          }}
        />
        <Price
          price={cartStore.cart.total.getPrice(withTax)}
          showTaxExcludedInfo={showTaxExcludedInfo}
        />
        <Button
          color="success"
          tag="a"
          href={checkout.checkoutPath}
          className="CommonFooter__cart-contents-button"
          onClick={onClickHandler}
          aria-label={intl.formatMessage(
            globalTranslations.proceedToCheckoutSentence
          )}
          disabled={cartStore.cartButtonDisabled}
        >
          <Icon name="shopping-cart" />
          {getCartButtonContent()}
        </Button>
      </div>
    );
  };

  const onClickHandler = () => {
    cartStore.toggleCartButton();
    sendAnalyticsCheckoutEvent();
  };

  const sendAnalyticsCheckoutEvent = () => {
    if (!configStore.gtm.enabled) {
      return;
    }

    let coupon;
    const activeCoupon = campaignCodeStore.activeCoupon;
    if (activeCoupon && !activeCoupon.hasProductLimitations()) {
      coupon = activeCoupon.code;
    }

    const withTax = accountStore.showPricesWithTax;
    const cartProducts = cartStore.cart.products;
    const actionField = { step: 1, option: 'Move To Checkout' };
    const value = cartStore.cart.getCartTotalValue(withTax);
    analytics.checkout(cartProducts, actionField, value, coupon);
  };

  const getCartButtonContent = () => {
    if (cartStore.cartButtonDisabled) {
      return <FormattedMessage {...globalTranslations.loading} />;
    }

    return <FormattedMessage {...globalTranslations.proceedToCartSentence} />;
  };

  const getNewsLetterForm = () => {
    return (
      <div className="CommonFooter__newsletter-container">
        {couponStore.newsletterCoupon && (
          <p className="CommonFooter__newsletter-discount-coupon-paragraph">
            <CouponText coupon={couponStore.newsletterCoupon} short />
          </p>
        )}
        <FormattedMessage
          id="newsletter.isFree"
          defaultMessage="Newsletter is free"
        />
        <NewsletterSubscriptionForm compact />
      </div>
    );
  };

  const getNewsletterColumn = () => {
    if (!configStore.newsletter.enabled) {
      return null;
    }

    return (
      <FooterColumn
        lg={12}
        md={12}
        xl={3}
        className="CommonFooter__newsletter-column"
        title={
          <FormattedMessage
            {...globalTranslations.newsletterSubscriptionSentence}
          />
        }
      >
        {getNewsLetterForm()}
      </FooterColumn>
    );
  };

  const getColumnFromEntity = (menu) => (
    <FooterColumn
      xs={6}
      className={menu.className}
      key={menu.id}
      title={menu.label}
    >
      {menu.children && <MenuItemList items={menu.children} />}
    </FooterColumn>
  );

  const getColumnFooterItems = () => {
    const entities = getMenuEntities();
    let menuColumnSizes = {};
    let menus, singleMenus, multipleMenus;

    if (entities) {
      menus = entities.filter(
        (menu) => menu.children && menu.children.length > 0
      );
      multipleMenus = menus.length > 1;
      if (multipleMenus) {
        menuColumnSizes = {
          xl: 4,
          md: 6,
          xs: 12,
        };
      }

      singleMenus = entities.filter(
        (menu) => menu.children && menu.children.length === 0
      );
    }

    const columns = [].concat(getStaticDesktopColumnEntities());
    if (uiStore.isMobile && singleMenus && singleMenus.length > 0) {
      columns.push(getSingleLinkEntities(singleMenus));
    }

    if (!configStore.siteConfig.isShoppingCenter) {
      columns.push(getAccountColumnEntities());
    }

    return (
      <>
        {!configStore.siteConfig.isHomePage && (
          <div className="CommonFooter__links">
            <Container className="CommonFooter__menu-container">
              <Row>
                <Col xl={'1-24'} />
                {menus && menus.length > 0 && (
                  <FooterColumn
                    {...menuColumnSizes}
                    className={classNames('CommonFooter__menu-column', {
                      'CommonFooter__menu-column--multiple-menus':
                        multipleMenus,
                    })}
                  >
                    {menus.map((menu) => (
                      <div
                        className="CommonFooter__menu-column-menu"
                        key={menu.id}
                      >
                        <h5>{menu.label}</h5>
                        <MenuItemList items={menu.children} />
                      </div>
                    ))}
                  </FooterColumn>
                )}
                {columns.map((menuItem) => getColumnFromEntity(menuItem))}
                {getNewsletterColumn()}
              </Row>
            </Container>
          </div>
        )}
        {configStore.visibility.footer.showManufacturers && !uiStore.isMobile && (
          <div className="CommonFooter__manufacturers">
            <Container className="CommonFooter__manufacturers-container">
              <Row>
                <Col xl={{ size: 11, offset: '1-24' }} lg="12">
                  <FooterManufacturers />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </>
    );
  };

  const getLinkFooterItems = () => {
    const staticItems = getStaticLinkEntities();
    const menuEntities = getMenuEntities();
    let items;

    if (menuEntities) {
      items = [].concat(
        staticItems.beforeMenuItems,
        menuEntities,
        staticItems.afterMenuItems
      );
    } else {
      items = [].concat(
        staticItems.beforeMenuItems,
        staticItems.afterMenuItems
      );
    }

    return (
      <div className="CommonFooter__links-small">
        {items.map((item) => {
          const getMenuIcon = () => {
            if (item.id === BRANDS_ID) {
              return <Icon name="chevron-right" />;
            }

            if (item.id === INFO_CALL_PHONE_ID) {
              return <Icon name="phone" />;
            }

            if (item.id === INFO_SEND_EMAIL_ID) {
              return <Icon name="envelope" />;
            }

            if (item.children?.length === 0) {
              return <Icon name="chevron-right" />;
            }

            return null;
          };

          return (
            <div className="CommonFooter__links-small-link" key={item.id}>
              <CollapsibleMenu item={item} icon={getMenuIcon()} />
            </div>
          );
        })}
      </div>
    );
  };

  const getDefaultMobileMenuTab = () => {
    if (
      matchPath(location.pathname, {
        path: '/:locale?' + Paths.InfoPage,
      })
    ) {
      return MobileNavigationTab.INFO;
    }

    return MobileNavigationTab.PRODUCTS;
  };

  const getFooterItems = () => {
    return uiStore.isDesktop || configStore.visibility.footer.useMobileColumns
      ? getColumnFooterItems()
      : getLinkFooterItems();
  };

  const isCartExpirable =
    !accountStore.isViewOnly &&
    cartStore.cart &&
    cartStore.cart.expires_at &&
    cartStore.cart.number_of_products > 0;
  const showMobileAssistMenu =
    configStore.mobileSettings.showAssistantMenu && uiStore.isMobile;

  return (
    <div className="CommonFooter">
      {!shouldHideMixedContent() && (
        <SSRAwareLazyLoad offset={50}>
          <FooterBanner />
        </SSRAwareLazyLoad>
      )}
      {isCartExpirable && <FooterCartExpirationBanner />}
      <FooterBackToTopButton />
      {!shouldHideMixedContent() && showMobileAssistMenu && (
        <div className="CommonFooter__mobile-assist">
          <NavigationAssistButton tab={getDefaultMobileMenuTab()} />
        </div>
      )}
      {!shouldHideMixedContent() &&
        menuStore.state === RequestState.LOADED &&
        getFooterItems()}
      {!shouldHideMixedContent() && !configStore.siteConfig.isHomePage && (
        <SSRAwareLazyLoad offset={50}>
          <Container className="FooterPaymentModules__container">
            <Row>
              <Col xs="12">
                <FooterPaymentModules />
              </Col>
            </Row>
          </Container>
        </SSRAwareLazyLoad>
      )}
      <FooterCopyright />
    </div>
  );
};

CommonFooter.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  campaignCodeStore: modelOf(CampaignCodeStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  couponStore: modelOf(CouponStore).isRequired,
  manufacturerStore: modelOf(ManufacturerStore).isRequired,
  menuStore: modelOf(MenuStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  intl: intlShape.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default injectIntl(
  withRouter(
    inject(
      'accountStore',
      'campaignCodeStore',
      'cartStore',
      'configStore',
      'couponStore',
      'menuStore',
      'uiStore',
      'sectionStore',
      'manufacturerStore',
      'routeService',
      'analytics'
    )(observer(CommonFooter))
  )
);
