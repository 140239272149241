import { types } from 'mobx-state-tree';

import PickUpPointInfo from '../PickUpPointInfo';
import OrderAddress from './OrderAddress';
import OrderMethod from './OrderMethod';
import OrderTracking from './OrderTracking';

const OrderShipping = types
  .model('OrderShipping', {
    address: types.optional(OrderAddress, {}),
    method: types.maybeNull(types.optional(OrderMethod, {})),
    pick_up_point: types.maybeNull(PickUpPointInfo),
    tracking: types.optional(types.array(OrderTracking), []),
  })
  .views((self) => ({
    get hasTrackingInfo() {
      return self.tracking.length > 0;
    },
  }));

export default OrderShipping;
