import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import RouterPropTypes from 'react-router-prop-types';

import { modelOf } from '../../../prop-types';
import ScriptStore from '../../../store/ScriptStore';
import UIStore from '../../../store/UIStore';
import { parse } from '../../../util/queryString';

const PAGE_LOCATIONS = {
  HEAD_START: 'HEAD_START',
  HEAD_END: 'HEAD_END',
  BODY_START: 'BODY_START',
  BODY_END: 'BODY_END',
};

const SCRIPT_STATUSES = {
  ACTIVE: 'ACTIVE',
  TEST_MODE: 'TEST_MODE',
};

@observer
export class UniversalJavascriptTool extends Component {
  constructor(props) {
    super(props);
    this.key = 0;
  }

  componentDidMount() {
    this.getHeadStartScripts();
    this.getHeadEndScripts();
    this.getBodyStartScripts();
    this.getBodyEndScripts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getHeadStartScripts();
      this.getHeadEndScripts();
      this.getBodyStartScripts();
      this.getBodyEndScripts();
    }
  }

  getHeadStartScripts = () => {
    const scripts = this.getScripts(PAGE_LOCATIONS.HEAD_START);
    const headScripts = document.head.getElementsByTagName('meta');

    scripts.forEach((script) => {
      const parsedScripts = this.getParsedScripts({
        script,
      });
      const element = document.getElementById(script.id);

      if (parsedScripts.length > 0) {
        parsedScripts.forEach((parsedScript) => {
          const scriptNode = this.createScript(parsedScript);

          if (element) {
            element.remove();
          }

          document.head.insertBefore(scriptNode, headScripts[0]);
        });
      }
    });
  };

  getHeadEndScripts = () => {
    const scripts = this.getScripts(PAGE_LOCATIONS.HEAD_END);

    scripts.forEach((script) => {
      if (window.isSSR && !script.execute_on_ssr) {
        return;
      }

      const parsedScripts = this.getParsedScripts({
        script,
      });
      const element = document.getElementById(script.id);

      if (parsedScripts.length > 0) {
        parsedScripts.forEach((parsedScript) => {
          const scriptNode = this.createScript(parsedScript);

          if (element) {
            element.remove();
          }
          document.head.appendChild(scriptNode);
        });
      }
    });
  };

  getBodyStartScripts = () => {
    const scripts = this.getScripts(PAGE_LOCATIONS.BODY_START);
    const root = document.getElementById('root');

    scripts.forEach((script) => {
      const parsedScripts = this.getParsedScripts({
        script,
      });
      const element = document.getElementById(script.id);

      if (parsedScripts.length > 0) {
        parsedScripts.forEach((parsedScript) => {
          const scriptNode = this.createScript(parsedScript);

          if (element) {
            element.remove();
          }

          document.body.insertBefore(scriptNode, root);
        });
      }
    });
  };

  getBodyEndScripts = () => {
    const scripts = this.getScripts(PAGE_LOCATIONS.BODY_END);

    scripts.forEach((script) => {
      const parsedScripts = this.getParsedScripts({ script });
      const element = document.getElementById(script.id);

      if (parsedScripts.length > 0) {
        parsedScripts.forEach((parsedScript) => {
          const scriptNode = this.createScript(parsedScript);

          if (element) {
            element.remove();
          }

          document.body.appendChild(scriptNode);
        });
      }
    });
  };

  getScripts = (pageLocation) => {
    const { scriptStore, uiStore, location } = this.props;
    const queryParams = parse(location.search);

    let status = '';
    if (Number(queryParams.allowTestModeScripts)) {
      status = SCRIPT_STATUSES.TEST_MODE;
    } else {
      status = SCRIPT_STATUSES.ACTIVE;
    }

    return scriptStore.getScripts({
      pageType: uiStore.currentPage,
      location: pageLocation,
      status,
    });
  };

  getParsedScripts = ({ script }) => {
    const doc = document.implementation.createHTMLDocument('');
    doc.body.innerHTML = script.code;

    return [].map.call(doc.getElementsByTagName('script'), (el) => {
      const attributes = this.getElementAttributes({ element: el, script });
      attributes.textContent = el.textContent;
      return attributes;
    });
  };

  getElementAttributes = ({ element, script }) => {
    const attributes = { key: this.key++ };
    Array.from(element.attributes).forEach((attr) => {
      attributes[attr.name] = attr.value;
    });

    attributes.id = script.id;
    attributes.async = true;

    return attributes;
  };

  createScript = (parsedScript) => {
    const scriptNode = document.createElement('script');
    scriptNode.id = parsedScript.id;
    scriptNode.async = true;

    if (parsedScript.src) {
      scriptNode.src = parsedScript.src;
    }

    scriptNode.textContent = parsedScript.textContent;
    scriptNode.onerror = (err) => console.log(err);

    return scriptNode;
  };

  render() {
    // Bail if we are in SSR.
    if (typeof document === 'undefined') {
      return null;
    }

    return <noscript>You need to enable JavaScript</noscript>;
  }
}

UniversalJavascriptTool.propTypes = {
  scriptStore: modelOf(ScriptStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default withRouter(
  inject('scriptStore', 'uiStore')(UniversalJavascriptTool)
);
