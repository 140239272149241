import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import AccountModalLink from '../AccountModalLink';
import AccountModalTab from '../../../types/AccountModalTab';
import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

const LoginLink = ({ configStore }) => {
  if (configStore.account.disableLogin) {
    return null;
  }

  return (
    <AccountModalLink className="LoginLink" tab={AccountModalTab.LOGIN}>
      <Icon name="unlock-alt" />
      <FormattedMessage {...globalTranslations.logInSentence} />
    </AccountModalLink>
  );
};

LoginLink.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(observer(LoginLink));
