import classNames from 'classnames';
import { isEqual } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import { Container } from 'reactstrap';

import Analytics from '../../analytics/Analytics';
import ContentPopups from '../../components/ad/ContentPopups';
import NavigationAddToCartRow from '../../components/cart/NavigationAddToCartRow';
import HelperNavigation from '../../components/common/HelperNavigation';
import ScrollReset from '../../components/common/ScrollReset';
import SharePopover from '../../components/common/SharePopover';
import GenericMeta from '../../components/head/GenericMeta';
import Page from '../../components/layout/Page';
import SidebarLayout from '../../components/layout/SidebarLayout';
import ContentForState from '../../components/loader/ContentForState';
import ProductPageContent from '../../components/product/ProductPageContent';
import ProductBaseSchema from '../../components/schema/product/ProductBaseSchema';
import DefaultSidebar from '../../components/sidebar/DefaultSidebar';
import MobilePageSkeleton from '../../components/skeleton/MobilePageSkeleton';
import SidebarSkeleton from '../../components/skeleton/SidebarSkeleton';
import globalUserMessages from '../../i18n/globalUserMessages';
import { modelOf } from '../../prop-types';
import { LocationContextPropType } from '../../services/LocationContext';
import RouteService from '../../services/RouteService';
import AccountStore from '../../store/AccountStore';
import AdStore from '../../store/AdStore';
import CartStore from '../../store/CartStore';
import CategoryStore from '../../store/CategoryStore';
import ConfigStore from '../../store/ConfigStore';
import ProductStore from '../../store/ProductStore';
import SectionStore from '../../store/SectionStore';
import TrackingStore from '../../store/TrackingStore';
import UIStore from '../../store/UIStore';
import CommonPage from '../../types/CommonPage';
import Paths from '../../types/Paths';
import ProductClass from '../../types/ProductClass';
import ProductTypeClass from '../../types/ProductTypeClass';
import RequestState from '../../types/RequestState';
import TrackingEventType from '../../types/TrackingPageType';
import { globalUserMessageDataKey } from '../../util/constants';
import generatePath from '../../util/generatePath';
import { blockTestRender } from '../../util/process';
import {
  addParametersToPath,
  parse,
  removeQueryParameters,
} from '../../util/queryString';
import {
  fixPercentDecodingForParam,
  pathFragmentMatchesLocation,
} from '../../util/url';

@observer
class ProductPage extends Component {
  constructor(props) {
    super(props);
    this.initProductLoad();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;

    if (prevProps.match.params.id !== match.params.id) {
      this.initProductLoad();
    }

    if (
      prevProps.match.params.activeProductId !== match.params.activeProductId
    ) {
      this.loadSizeGuides();
    }

    if (
      !window.isSSR &&
      prevProps.match.params.activeProductId !== match.params.activeProductId
    ) {
      this.sendTrackingData();
    }
  }

  componentWillUnmount() {
    const { categoryStore } = this.props;
    // Clean-up active category
    categoryStore.setActiveCategory(null);
  }

  initProductLoad = () => {
    /**
     * Product state gets stuck displaying "LOADING"-state on SSR because
     * constructor is invoked multiple times, presumably causing new API queries each invocation.
     * There's probably the same "state getting stuck"-issue with all the content
     * that's been rendered asynchronously on SSR.
     */
    const id = this.getProductId();
    if (window.isSSR) {
      this.maybeLoadSSRProduct(id);
    } else {
      this.maybeLoadProduct(id);
    }
  };

  maybeLoadSSRProduct = (id) => {
    if (!this.props.productStore.productStates.get(id)) {
      this.maybeLoadProduct(id);
    }
  };

  maybeLoadProduct = (id) => {
    const {
      categoryStore,
      productStore,
      productParams,
      location,
      routeService,
    } = this.props;

    let params = null;
    if (productParams) {
      params = productParams;
    } else if (location.search) {
      params = parse(location.search);
    }

    productStore
      .loadProduct(id, params)
      .then((fixedId) => {
        this.onProductLoad(fixedId);
      })
      .catch((e) => {
        if (
          productStore.lastError &&
          productStore.lastError.httpStatusCode === 404 &&
          productStore.lastError.fallbackCategoryId
        ) {
          // CustomEvent is not supported by node.
          if (!window.isSSR) {
            const customEvent = new CustomEvent(
              globalUserMessageDataKey.globalUserMessage,
              {
                detail: {
                  ...globalUserMessages.productNotExist,
                },
              }
            );

            window.dispatchEvent(customEvent);
          }

          const path =
            categoryStore.findCategoryById(
              productStore.lastError.fallbackCategoryId
            )?.path ?? Paths.FrontPage;

          return <Redirect to={routeService.getPath(path)} />;
        }

        // Don't make error out of 404 product api response,
        // so sentry won't pick it up.
        if (e.response && e.response.status !== 404) {
          console.error(e);
        }
      });
  };

  loadSizeGuides = () => {
    const product = this.getMatchingProduct();
    if (product.product_type === ProductTypeClass.GIFT_VOUCHER) {
      return;
    }

    const sizeGuidesParams = this.getSizeGuideParams(product);
    const activeProductId = this.getActiveProductId();

    this.getProductSizeGuides(activeProductId, product, sizeGuidesParams);
    this.getActiveProductSizeGuides(activeProductId, product, sizeGuidesParams);
  };

  getProductSizeGuides = (activeProductId, product, sizeGuidesParams) => {
    if (
      !activeProductId &&
      product &&
      product.sizeGuidesState === RequestState.NONE
    ) {
      product.loadSizeGuides(sizeGuidesParams).catch((e) => {
        if (e.response && e.response.status !== 404) {
          console.error(e);
        }
      });
    }
  };

  getActiveProductSizeGuides = (activeProductId, product, sizeGuidesParams) => {
    const actualProduct = product.getActualProduct(
      activeProductId || product.id
    );

    if (
      activeProductId &&
      actualProduct &&
      actualProduct.sizeGuidesState === RequestState.NONE
    ) {
      actualProduct.loadSizeGuides(sizeGuidesParams).catch((e) => {
        if (e.response && e.response.status !== 404) {
          console.error(e);
        }
      });
    }
  };

  loadBanners = () => {
    const { adStore } = this.props;
    const params = this.getAdSearchParams();

    if (this.shouldHaveAds()) {
      adStore.loadAds(params, '/product').catch((e) => {
        console.error(e);
      });
    }
  };

  shouldHaveAds = () => {
    const { adStore } = this.props;
    return adStore.productPageAds.length > 0;
  };

  shouldHaveSidebarAds = () => {
    const { adStore } = this.props;
    return adStore.productPageAds.indexOf('SIDEBAR_BANNER') !== -1;
  };

  shouldHavePopup = () => {
    const { adStore } = this.props;
    return adStore.productPageAds.indexOf('POPUP_CONTENT') !== -1;
  };

  onProductLoad = (fixedId) => {
    const { analytics, accountStore, productStore, routeService, match } =
      this.props;

    if (fixedId && !isEqual(match.params.id, fixedId)) {
      const product = this.getFixedProduct(fixedId);
      const productPath = routeService.getProductPath(product);
      if (product) {
        this.navigateTo(productPath);
      }
    }

    const product = this.getMatchingProduct();

    if (product) {
      const activeProductId = this.getActiveProductId();

      this.maybeUpdateActiveCategory(product);

      analytics.productDetail({
        productList: [
          {
            product,
            activeProductId,
          },
        ],
        value: product.getPriceWithPrecision(
          accountStore.showPricesWithTax,
          activeProductId
        ),
      });

      // Banners and size-guides API needs active section and category.
      this.loadBanners();
      this.loadSizeGuides();

      productStore.insertLastVisitedProduct(product.id);
      !window.isSSR && this.sendTrackingData();
    }
  };

  getActiveHierarchyCategory = () => {
    const { categoryStore } = this.props;
    const product = this.getMatchingProduct();

    // Return only one result.
    return product.allCategories.filter((category) => {
      if (category.id !== product.main_category_id) {
        if (
          category.hierarchy.findIndex(
            (filteredCategory) =>
              filteredCategory.id === categoryStore.latestActiveCategory.id
          ) !== -1
        ) {
          return true;
        }
      }
      return false;
    })[0];
  };

  getCategoryBySection = () => {
    const { sectionStore } = this.props;
    const product = this.getMatchingProduct();

    // Will return one result if the current hierarchy is not the main one.
    return product.allCategories.filter((category) => {
      if (category.id !== product.main_category_id) {
        if (
          category.hierarchy.findIndex((filteredCategory) =>
            sectionStore.activeSection.includesCategory(filteredCategory)
          ) !== -1
        ) {
          return true;
        }
      }
      return false;
    })[0];
  };

  maybeUpdateActiveCategory = (product) => {
    const { categoryStore, sectionStore } = this.props;

    // Default category is products main category.
    let activeCategory = product.main_category_id;

    if (product.allCategories.length === 1) {
      categoryStore.setActiveCategory(activeCategory);
      return;
    }

    const mainCategory = product.main_category_id
      ? categoryStore.findCategoryById(product.main_category_id)
      : null;

    if (categoryStore.latestActiveCategory) {
      const activeIsMainCategory =
        mainCategory &&
        mainCategory.hierarchy.findIndex(
          (category) => category.id === categoryStore.latestActiveCategory.id
        ) !== -1;

      // When currently active category is not its main category
      // the correct one needs to be scanned from product other categories.
      if (
        !activeIsMainCategory &&
        product.belongsToCategory(categoryStore.latestActiveCategory.id)
      ) {
        const targetCategory = this.getActiveHierarchyCategory();
        if (targetCategory) {
          activeCategory = targetCategory.id;
        }
      }
    } else {
      if (sectionStore.activeSection) {
        const target = this.getCategoryBySection();
        if (target) {
          activeCategory = target.id;
        }
      }
    }
    categoryStore.setActiveCategory(activeCategory);
  };

  sendTrackingData = () => {
    const { trackingStore, categoryStore, configStore } = this.props;

    const product = this.getMatchingProduct();
    const actualProduct =
      product && product.getActualProduct(this.getActiveProductId());
    if (
      !configStore.integrations.spotmore.enabled ||
      typeof document === 'undefined' ||
      !actualProduct ||
      !this.getActiveProductId()
    ) {
      return null;
    }

    if (configStore.integrations.spotmore.enabled && window.crmtracker) {
      window.crmtracker.sendData();
    }

    trackingStore.setPageView({
      categoryId: categoryStore.activeCategory
        ? categoryStore.activeCategory.id
        : null,
      categoryName: categoryStore.activeCategory
        ? categoryStore.activeCategory.name
        : null,
      page: TrackingEventType.PRODUCT,
      parentCategoryId: categoryStore.activeCategory
        ? categoryStore.activeCategory.parent_id
        : null,
      productActive: typeof actualProduct !== 'undefined',
      productId: this.getActiveProductId(),
      productName: actualProduct.name,
      refererUrl: document.referrer,
      visitedUrl: window.location.href,
    });
    trackingStore.postPageView();
  };

  navigateTo = (path) => {
    this.props.history.replace(path);
  };

  redirectToProduct = (product) => {
    const { routeService } = this.props;
    const productPath = routeService.getProductPath(product);
    return <Redirect to={productPath} />;
  };

  setQueryParams = () =>
    removeQueryParameters({
      location: this.props.location,
      params: ['columnId', 'rowId'],
    });

  setActiveProductId = ({ activeProductId, queryParams }) => {
    const { match, history } = this.props;
    // Store all query params to reinsert back the remaining params we want to preserve in the url
    // after history.replace
    const allQueryParams = this.setQueryParams();
    const params = { ...match.params, activeProductId };

    const activeProductPath = addParametersToPath(
      generatePath(match.path, params),
      {
        ...queryParams,
        ...allQueryParams,
      }
    );

    history.replace(activeProductPath);
  };

  getMatchingProduct = (fixedId) => {
    return this.props.productStore.products.get(
      fixedId ? fixedId : this.getProductId()
    );
  };

  getFixedProduct = (fixedId) => {
    return this.props.productStore.products.get(fixedId);
  };

  // TODO
  // This function is a workaround for an issue regarding percent encoded
  // URLs in React Router and its history library.
  // It should be fixed in the next version of history > 4.9.0, which had
  // not yet been published at the time of this alternate solution;
  // when that version is out, this fix can be removed.
  //
  // See: https://github.com/ReactTraining/history/issues/505
  getProductId = () => {
    return fixPercentDecodingForParam(this.props.match.params.id);
  };

  // The same workaround is also applied here.
  getActiveProductId = () => {
    const { match } = this.props;
    const product = this.getMatchingProduct();

    let activeProductId;
    if (product.class === ProductClass.COLLECTION) {
      activeProductId = match.params.activeProductId;
    } else if (product.class === ProductClass.MULTI) {
      activeProductId = match.params.activeProductId;
    } else {
      activeProductId = product.id;
    }

    return fixPercentDecodingForParam(activeProductId);
  };

  getShareImageUrl = () => {
    const { locationContext } = this.props;
    const product = this.getMatchingProduct();
    if (!product || !product.images || product.images.length === 0) {
      return null;
    }
    const productImage = product.images[0];

    return productImage.sizes['full'].length > 0 &&
      productImage.sizes['full'].startsWith('http')
      ? productImage.sizes['full']
      : `${locationContext.protocol}//${locationContext.host}${productImage.sizes['full']}`;
  };

  getAdSearchParams = () => {
    const { categoryStore, sectionStore } = this.props;
    const product = this.getMatchingProduct();
    if (!product) {
      return null;
    }

    const commonAdParams = {
      product: product.id,
    };

    if (sectionStore.activeSection) {
      commonAdParams.section = sectionStore.activeSection.id;
    }

    if (categoryStore.activeCategory) {
      commonAdParams.category = categoryStore.activeCategory.id;
    }

    if (product.all_category_ids.length > 0) {
      commonAdParams.allCategories = product.all_category_ids;
    }

    if (product.manufacturer_id) {
      commonAdParams.manufacturer = product.manufacturer_id;
    }

    return commonAdParams;
  };

  getSizeGuideParams = (product) => {
    const { categoryStore, sectionStore } = this.props;
    if (!product) {
      return null;
    }

    const commonSizeGuideParams = {};

    if (sectionStore.activeSection) {
      commonSizeGuideParams.section = sectionStore.activeSection.id;
    }

    if (categoryStore.activeCategory) {
      commonSizeGuideParams.category = categoryStore.activeCategory.id;
    }

    return commonSizeGuideParams;
  };

  renderShareButtons = (media) => {
    const { locationContext } = this.props;
    const product = this.getMatchingProduct();

    return (
      <SharePopover
        media={media}
        productId={product.id}
        url={locationContext.href}
        title={window.document.title || ''}
      />
    );
  };

  getManufacturerId = () => {
    const product = this.getMatchingProduct();
    return product && product.manufacturer && product.manufacturer.id;
  };

  render() {
    const {
      cartStore,
      categoryStore,
      productStore,
      routeService,
      sectionStore,
      uiStore,
      location,
      match,
    } = this.props;

    const product = this.getMatchingProduct();
    const breadcrumbs = product
      ? routeService.transformBreadCrumbs(
          product.breadcrumbsForCategory(categoryStore.activeCategory)
        )
      : [];

    // If the product does not belong to the active section, redirect.
    if (
      product &&
      ((sectionStore.activeSection &&
        !product.belongsToSection(sectionStore.activeSection.id)) ||
        (!sectionStore.activeSection &&
          product.belongsToSomeSection(sectionStore.sectionIds)))
    ) {
      return <Redirect to={routeService.getProductPath(product)} />;
    }

    if (product) {
      const activeProductId = this.getActiveProductId();
      const expectedPath =
        activeProductId && match.params.activeProductId
          ? product.pathWithActiveProductId(activeProductId)
          : product.path;

      if (!pathFragmentMatchesLocation(expectedPath, location.pathname)) {
        const url = `${routeService.getProductPath(product, expectedPath)}${
          location.search
        }${location.hash}`;
        return <Redirect to={url} />;
      }
    }

    // If active collection or multi item is not valid, redirect to parent.
    if (product && product.class === ProductClass.COLLECTION) {
      if (
        this.getActiveProductId() &&
        !product.collection.getItemWithProductId(this.getActiveProductId())
      ) {
        return this.redirectToProduct(product);
      }
    }

    if (product && product.class === ProductClass.MULTI) {
      if (
        this.getActiveProductId() &&
        !product.getActualProduct(this.getActiveProductId())
      ) {
        return this.redirectToProduct(product);
      }
    }

    // If the product is MULTI_CHILD, redirect to parent
    if (product && product.class === ProductClass.MULTI_CHILD) {
      const productPath = routeService.getProductPath(
        product,
        product.pathWithActiveProductId(product.id)
      );

      return <Redirect to={productPath} />;
    }

    const adSearchParams = this.getAdSearchParams();
    const shareImageUrl = this.getShareImageUrl();
    const shareButtons = product && this.renderShareButtons(shareImageUrl);

    return (
      <Page
        name={CommonPage.PRODUCT}
        className={classNames('ProductPage', this.getManufacturerId(product))}
        onDefinedTrackingPage={TrackingEventType.PRODUCT}
      >
        <ScrollReset key={this.getProductId()} />
        {product && (
          <NavigationAddToCartRow
            product={product}
            activeProductId={this.getActiveProductId()}
          />
        )}
        <Container className="ProductPage__container">
          <SidebarLayout
            sidebarPlaceHolder={!uiStore.isMobile ? <SidebarSkeleton /> : null}
            sidebar={
              !uiStore.isMobile &&
              product && (
                <DefaultSidebar
                  bannerSearchParams={
                    this.shouldHaveSidebarAds() ? adSearchParams : null
                  }
                />
              )
            }
            content={
              <>
                {!uiStore.isMobile && (
                  <HelperNavigation
                    breadcrumbsPath={breadcrumbs}
                    withGoBackLink
                    rightSide={shareButtons}
                  />
                )}
                <ContentForState
                  state={productStore.productStates.get(this.getProductId())}
                  error={productStore.lastError}
                  forPlaceHolder={
                    uiStore.isMobile &&
                    blockTestRender() && <MobilePageSkeleton />
                  }
                  forLoaded={() => {
                    const queryParams = parse(location.search);
                    const matchingProduct = this.getMatchingProduct();
                    return (
                      <>
                        <GenericMeta name="twitter:card" content="summary" />
                        <GenericMeta
                          property="og:image"
                          content={shareImageUrl}
                        />
                        <GenericMeta
                          property="og:type"
                          content="product.item"
                        />
                        <ProductBaseSchema product={matchingProduct} />
                        <ProductPageContent
                          shareButtons={shareButtons}
                          product={matchingProduct}
                          activeProductId={this.getActiveProductId()}
                          setActiveProductId={this.setActiveProductId}
                          queryParams={queryParams}
                          adSearchParams={adSearchParams}
                          cartLoaded={cartStore.state === RequestState.LOADED}
                        />
                      </>
                    );
                  }}
                />
                {this.shouldHavePopup() && (
                  <ContentPopups searchParams={adSearchParams} />
                )}
              </>
            }
          />
        </Container>
      </Page>
    );
  }
}

ProductPage.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  adStore: modelOf(AdStore).isRequired,
  cartStore: modelOf(CartStore),
  categoryStore: modelOf(CategoryStore),
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore),
  sectionStore: modelOf(SectionStore),
  trackingStore: modelOf(TrackingStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics),
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  locationContext: LocationContextPropType.isRequired,
  history: RouterPropTypes.history.isRequired,
  location: RouterPropTypes.location.isRequired,
  match: RouterPropTypes.match.isRequired,
};

export default inject(
  'accountStore',
  'adStore',
  'cartStore',
  'categoryStore',
  'configStore',
  'productStore',
  'sectionStore',
  'trackingStore',
  'uiStore',
  'analytics',
  'routeService',
  'locationContext'
)(withRouter(ProductPage));
