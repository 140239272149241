import loadable from '@loadable/component';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, useHistory, useLocation } from 'react-router-dom';

import './styles/main.css';

import Analytics from './analytics/Analytics';
import CriteoAnalytics from './analytics/CriteoAnalytics';
import FacebookPixel from './analytics/FacebookPixel';
import Giosg from './analytics/Giosg';
import GoogleTagManager from './analytics/GoogleTagManager';
import AccountModal from './components/account/AccountModal';
import LoginPageContent from './components/account/LoginPageContent';
import CartErrorModal from './components/cart/CartErrorModal';
import CartModal from './components/cart/CartModal';
import CheckoutFooter from './components/checkout/CheckoutFooter';
import CheckoutNavigation from './components/checkout/CheckoutNavigation';
import GclidTracker from './components/common/GclidTracker';
import MobileRegionalSettingsModal from './components/common/MobileRegionalSettingsModal';
import ScrollTracker from './components/common/ScrollTracker';
import StoreGarbageCollector from './components/common/StoreGarbageCollector';
import TimerMessage from './components/common/TimerMessage';
import UniversalJavascriptTool from './components/common/UniversalJavascriptTool';
import CookieNotice from './components/cookie-notice/CookieNotice';
import ActivatedCoupons from './components/coupon/ActivatedCoupons';
import TimerCoupons from './components/coupon/TimerCoupons';
import FatalError from './components/error/FatalError';
import CommonFooter from './components/footer/CommonFooter';
import RecaptchaField from './components/form/RecaptchaField';
import GenericMeta from './components/head/GenericMeta';
import RobotsMeta from './components/head/RobotsMeta';
import SEOSchema from './components/head/SEOSchema';
import StyleOverrides from './components/head/StyleOverrides';
import CommonNavigation from './components/header/CommonNavigation';
import UninitializedAppSpinner from './components/loader/UninitializedAppSpinner';
import RedirectModal from './components/localization/RedirectModal';
import SpotmoreWidget from './components/marketing/SpotmoreWidget';
import ProposalModal from './components/proposal/ProposalModal';
import YotpoReviewWrapper from './components/review/YotpoReviewWrapper';
import AppRoutes from './components/route/AppRoutes';
import LogoutParamRedirect from './components/route/LogoutParamRedirect';
import PasswordResetRedirect from './components/route/PasswordResetRedirect';
import RouteHistoryKeeper from './components/route/RouteHistoryKeeper';
import SurveyModal from './components/surveys/SurveyModal';
import IntlWrapper from './i18n/IntlWrapper';
import PostAffiliateWidget from './integrations/postaffiliate/PostAffiliateWidget';
import ServiceFormWidget from './integrations/serviceForm/ServiceFormWidget';
import { modelOf } from './prop-types';
import ApiWrapper from './services/ApiWrapper';
import RootStore from './store';
import LocalStorageKey from './types/LocalStorageKey';
import Paths from './types/Paths';
import RequestState from './types/RequestState';
import { browserLanguage } from './util/browser';
import {
  eventTypes,
  globalUserMessageDataKey,
  storageKeys,
  urlParams,
} from './util/constants';
import * as queryString from './util/queryString';
import setResizeListener from './util/setResizeListener';
import { getStorageAndParse, stringifyAndSetStorage } from './util/storage';
import { parseLanguageCode } from './util/url';

const MobileNavigation = loadable(() =>
  import(
    /* webpackChunkName: "navigation" */ './components/header/MobileNavigation'
  )
);

const NavigationOffCanvas = loadable(() =>
  import(
    /* webpackChunkName: "navigation" */ './components/navigation/NavigationOffCanvas'
  )
);

const isSSR = window.isSSR;

const App = (props) => {
  const { apiWrapper, analytics, store, baseUrl } = props;
  const {
    accountStore,
    categoryStore,
    cartStore,
    configStore,
    countryStore,
    currencyStore,
    languageStore,
    sectionStore,
    translationStore,
    uiStore,
  } = store;

  const [hasDefaultConsent, setHasDefaultConsent] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);
  let processEventTimeout = null;

  useEffect(() => {
    window.addEventListener(eventTypes.cmpReady, () => {
      if (!hasDefaultConsent) {
        setHasDefaultConsent(true);
      }
    });

    window.addEventListener(
      globalUserMessageDataKey.globalUserMessage,
      (event) => {
        handleGlobalMessages(event.detail);
      }
    );

    return () => {
      window.removeEventListener(eventTypes.cmpReady, (event) => {
        setHasDefaultConsent(true);
      });
      document.removeEventListener(
        globalUserMessageDataKey.globalUserMessage,
        (event) => {
          handleGlobalMessages(event.detail);
        }
      );

      clearTimeout(processEventTimeout);
    };
  }, []);

  setResizeListener(uiStore.setViewActiveBreakpoint);

  // Set up the language for the first time. We only have the URL to go with at this point.
  // If the URL doesn't specify a language, we'll set it again after the bootstrap.
  const setLanguageCodeFromUrl = () => {
    let languageCode = parseLanguageCode(location.pathname);
    if (languageCode) {
      languageStore.setupLocale(languageCode);
      apiWrapper.setLanguageCode(languageCode);
    }
  };

  setLanguageCodeFromUrl();

  const setLocalStorageFromUrl = (params) => {
    // Check user choice for chosen site.
    if (params.siteChosen && params.siteChosen === '1') {
      stringifyAndSetStorage(LocalStorageKey.SITE_CHOSEN, true);
    }
  };

  setLocalStorageFromUrl(queryParams);

  const maybeSetProductListAccessTokenParamFromUrl = () => {
    const search = location.search;
    if (search.includes(urlParams.accessToken)) {
      const params = search.replace(`?${urlParams.accessToken}=`, '');
      history.push(Paths.WishList + params);
    }
  };

  maybeSetProductListAccessTokenParamFromUrl();

  if (store.state !== RequestState.LOADED) {
    store
      .bootstrap(
        queryParams.sellerID,
        queryParams.campaign_code,
        queryParams.allowTestModeScripts,
        queryParams.processLogout
      )
      .catch((e) => {
        // 503 is sent from backend for maintenance mode therefore error is not needed to print.
        if (e.response && e.response.status !== 503) {
          console.error(e.response);
        }
        // Catch errors in bootstrapping.
        if (!e.response) {
          console.error(e);
        }
      });
  }

  const setProductListGuestStorage = () => {
    const storage = getStorageAndParse(storageKeys.wishlist);
    if (!storage) {
      stringifyAndSetStorage(storageKeys.wishlist, []);
    }
  };

  const setProductListFeaturesIfEnabled = () => {
    if (!configStore.wishlist.enabled) {
      return;
    }

    setProductListGuestStorage();
  };

  const handleGlobalMessages = (data) => {
    uiStore.addEvent({ data });
    uiStore.processEvents();
    processEventTimeout = setTimeout(uiStore.resetEvent, 5000);
  };

  /**
   * Returns a switch with all the routes for the main content ("pages").
   * The routes have two optional prefixes: Language (code) and Section (slug).
   * Language prefixes are used, if there are more than 1 language.
   * Section prefixes are used, if sections are enabled.
   * @returns {Switch}
   */

  const allowIndexing = () => {
    const filterParams = store.configStore.seo.indexedParameters;

    filterParams.forEach((parameter) => {
      delete queryParams[parameter];
    });

    return Object.keys(queryParams).length === 0;
  };

  const getSchemaData = () => ({
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    name: props.store.configStore.store.name,
  });

  const getHeader = () => {
    return location.pathname.endsWith(Paths.ProposalCheckout) ? (
      <CheckoutNavigation />
    ) : (
      <CommonNavigation />
    );
  };

  const getFooter = () => {
    return location.pathname.endsWith(Paths.ProposalCheckout) ? (
      <CheckoutFooter />
    ) : (
      <CommonFooter />
    );
  };

  if (store.lastError) {
    const navigatorLanguage = browserLanguage();
    const overrideLocale = navigatorLanguage && navigatorLanguage.language;

    return (
      <IntlWrapper
        languageStore={languageStore}
        translationStore={translationStore}
        overrideLocale={overrideLocale}
      >
        <FatalError />
      </IntlWrapper>
    );
  }

  const loaded =
    configStore.state === RequestState.LOADED &&
    sectionStore.state === RequestState.LOADED &&
    languageStore.state === RequestState.LOADED &&
    languageStore.activeLanguage &&
    languageStore.hasBeenSetup &&
    currencyStore.activeCurrency &&
    (categoryStore.state === RequestState.LOADED ||
      categoryStore.state === RequestState.ERROR);

  // URLs with parameters should not be indexed.
  let shouldIndex = true;

  if (loaded) {
    shouldIndex = allowIndexing();
  }

  if (
    loaded &&
    !shouldIndex &&
    categoryStore.activeCategory &&
    configStore.suitableProducts.enabledOnCategories.indexOf(
      categoryStore.activeCategory.id
    ) !== -1
  ) {
    shouldIndex = true;
  }

  let loginRequired = false;
  let doLoadAccountModal = false;
  let doLoadCart = false;
  let doLoadProposalCart = false;

  if (loaded) {
    setProductListFeaturesIfEnabled();
    doLoadCart = !accountStore.isViewOnly && configStore.siteConfig.isStore;
    doLoadProposalCart =
      !accountStore.isViewOnly && configStore.siteConfig.proposalEnabled;
    loginRequired = configStore.store.loginRequired && !accountStore.loggedIn;
    configStore.analytics.ga4.enabled && analytics.reInitializeWithGA4();
    doLoadAccountModal =
      !loginRequired && !isSSR && accountStore.accountModal.isOpen;

    if (
      !hasDefaultConsent &&
      (isSSR || !configStore.integrations.serviceForm.enabled)
    ) {
      setHasDefaultConsent(true);
    }
  }

  const openGraphPrefix = 'og: https://ogp.me/ns#';
  const canLoadScripts = uiStore.currentPageIsSet && hasDefaultConsent;
  const ifCartError =
    cartStore.state === RequestState.LOADED &&
    cartStore.cart.ifRenderErrorModal;
  const baseHref = !!window.isSSR ? baseUrl : window.location.origin;
  const navigation = uiStore.navigationOffCanvas;
  const toggle = () => {
    navigation.toggle();
  };

  return (
    <div className="App">
      {loaded && !isSSR && configStore.integrations.serviceForm.enabled && (
        <ServiceFormWidget />
      )}
      <Route path="/" component={RouteHistoryKeeper} />
      {loaded ? (
        <IntlWrapper
          languageStore={languageStore}
          translationStore={translationStore}
        >
          <>
            {configStore.gclid.enabled && <GclidTracker location={location} />}
            <SEOSchema data={getSchemaData()} />
            <Helmet>
              <html
                lang={languageStore.activeLocale}
                prefix={openGraphPrefix}
              />
              <body
                className={classNames(
                  `category-${
                    categoryStore.activeCategory &&
                    categoryStore.activeCategory.id
                  }`,
                  `country-${
                    countryStore.activeCountry &&
                    countryStore.activeCountry.iso_code_2
                  }`,
                  `currency-${currencyStore.currencyCode}`,
                  `customer-group-${
                    accountStore.account &&
                    accountStore.account.customer_group_id
                  }`,
                  `language-${languageStore.activeLocale}`,
                  `path-${location.pathname}`,
                  `section-${
                    sectionStore.activeSection && sectionStore.activeSection.id
                  }`
                ).toLowerCase()}
              />
            </Helmet>
            <Helmet>
              <base href={baseHref} />
            </Helmet>
            {!shouldIndex && <RobotsMeta noindex />}
            {configStore.denyRobots && <RobotsMeta noindex nofollow />}
            {configStore.facebookVerification.length > 0 && (
              <GenericMeta
                name="facebook-domain-verification"
                content={configStore.facebookVerification}
              />
            )}

            {!isSSR && hasDefaultConsent && configStore.gtm.enabled && (
              <GoogleTagManager />
            )}
            {!isSSR && hasDefaultConsent && <CriteoAnalytics />}
            {!isSSR && hasDefaultConsent && <FacebookPixel />}
            {!isSSR && hasDefaultConsent && <Giosg />}
            {!isSSR && hasDefaultConsent && <YotpoReviewWrapper />}
            {!isSSR && hasDefaultConsent && <SpotmoreWidget />}

            {!isSSR && configStore.integrations.postAffiliate.enabled && (
              <PostAffiliateWidget />
            )}

            {!loginRequired && (
              <div>
                {getHeader()}
                <AppRoutes />
                {getFooter()}
              </div>
            )}
            {loginRequired && <LoginPageContent />}

            {!isSSR && <CookieNotice />}
            {!isSSR && <TimerCoupons />}
            {!isSSR && <ActivatedCoupons />}
            {!isSSR && <TimerMessage />}

            {doLoadAccountModal && <AccountModal />}
            {doLoadCart && <CartModal />}
            {doLoadProposalCart && <ProposalModal />}
            {ifCartError && <CartErrorModal />}
            {!isSSR && <MobileRegionalSettingsModal />}
            {!isSSR && <RedirectModal />}
            {!isSSR && <SurveyModal />}
            {!isSSR && (
              <NavigationOffCanvas
                open={navigation.isOpen}
                handleToggle={toggle}
              >
                {navigation.isOpen && (
                  <MobileNavigation
                    navigation={navigation}
                    open={navigation.isOpen}
                  />
                )}
              </NavigationOffCanvas>
            )}
            {!isSSR && configStore.recaptcha.enabled && <RecaptchaField />}
            <ScrollTracker />
            <PasswordResetRedirect />
            <StoreGarbageCollector />
            <LogoutParamRedirect />
            {!isSSR && canLoadScripts && <UniversalJavascriptTool />}

            <StyleOverrides />
          </>
        </IntlWrapper>
      ) : (
        <UninitializedAppSpinner />
      )}
    </div>
  );
};

App.propTypes = {
  store: modelOf(RootStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
  baseUrl: PropTypes.string,
};

export default inject('analytics', 'apiWrapper')(observer(App));
