import classNames from 'classnames';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import useMaybeExternalLink from '../../../hooks/navigation/useMaybeExternalLink';
import LocationContext from '../../../services/LocationContext';
import LinkType from '../../../types/LinkType';
import {
  isNotHandledByReactRouter,
  stripHostIfMatches,
} from '../../../util/url';

const MaybeExternalLink = ({
  locationContext,
  to,
  className,
  type,
  accessible,
  active,
  shouldBeVisible,
  children,
  ...rest
}) => {
  const { host } = locationContext;
  const [childPath, getLinkProps] = useMaybeExternalLink({
    path: to,
    type,
    host,
  });

  const isExternal = () => {
    return type && type === LinkType.EXTERNAL_LINK;
  };

  const classNameString = classNames('MaybeExternalLink', className, {
    active,
  });

  if (isExternal()) {
    return (
      <a
        href={childPath}
        className={classNameString}
        tabIndex={accessible ? undefined : '-1'}
        {...getLinkProps()}
        {...rest}
        draggable={false}
      >
        {children}
      </a>
    );
  }

  /* Some internal urls might have "external" element containing the domain */
  const url = stripHostIfMatches(childPath, host);
  const isNormalLink = isNotHandledByReactRouter(url);

  return isNormalLink ? (
    <a href={url} className={classNameString} draggable={false} {...rest}>
      {children}
    </a>
  ) : (
    <NavLink
      to={url}
      exact={true}
      tabIndex={accessible ? undefined : '-1'}
      className={classNameString}
      draggable={false}
      isActive={(match) => (typeof active === 'boolean' ? active : match)}
      {...rest}
    >
      {children}
    </NavLink>
  );
};

MaybeExternalLink.propTypes = {
  locationContext: PropTypes.instanceOf(LocationContext).isRequired,
  to: PropTypes.string.isRequired,
  accessible: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
  overrideTarget: PropTypes.string,
  type: PropTypes.string,
};

MaybeExternalLink.defaultProps = {
  accessible: true,
};

export default inject('locationContext')(MaybeExternalLink);
