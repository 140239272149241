import { inject, observer } from 'mobx-react';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import PageTitle from '../../common/PageTitle';
import SEOHomePage from '../../head/SEOHomePage';
import AccountModal from '../AccountModal';

const LoginPageContent = ({ configStore, intl }) => {
  const title =
    configStore.configurationTexts.CONF_SEO_TITLE ||
    intl.formatMessage(globalTranslations.homeTitle);

  return (
    <div className="LoginPageContent">
      <SEOHomePage />
      <PageTitle itemProp={title}>{title}</PageTitle>
      <AccountModal key={'LoginPageContent'} useAsModal={false} />
    </div>
  );
};

LoginPageContent.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(inject('configStore')(observer(LoginPageContent)));
