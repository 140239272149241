import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import OrderStatusClass from '../../../types/OrderStatusClass';
import Icon from '../../common/Icon';

import styles from '../../../styles/_exports.scss';

const labels = {
  [OrderStatusClass.PROCESSING]: (
    <FormattedMessage id="orderStatus.processing" defaultMessage="Processing" />
  ),
  [OrderStatusClass.READY]: (
    <FormattedMessage id="orderStatus.ready" defaultMessage="Ready" />
  ),
  [OrderStatusClass.CANCELED]: (
    <FormattedMessage id="orderStatus.canceled" defaultMessage="Canceled" />
  ),
  [OrderStatusClass.PENDING]: (
    <FormattedMessage id="orderStatus.pending" defaultMessage="Pending" />
  ),
  [OrderStatusClass.ACTIVE]: (
    <FormattedMessage id="orderStatus.active" defaultMessage="Active" />
  ),
  [OrderStatusClass.DEACTIVATED]: (
    <FormattedMessage
      id="orderStatus.deactivated"
      defaultMessage="Deactivated"
    />
  ),
  [OrderStatusClass.ON_HOLD]: (
    <FormattedMessage id="orderStatus.onHold" defaultMessage="On hold" />
  ),
};

class OrderStatusLabel extends Component {
  getColor = () => {
    let color;

    switch (this.props.status) {
      case OrderStatusClass.CANCELED:
      case OrderStatusClass.DEACTIVATED:
        color = styles['danger'];
        break;
      case OrderStatusClass.PENDING:
      case OrderStatusClass.PROCESSING:
      case OrderStatusClass.ON_HOLD:
        color = styles['warning'];
        break;
      case OrderStatusClass.READY:
      case OrderStatusClass.ACTIVE:
        color = styles['success'];
        break;
      default:
        return null;
    }
    return color;
  };

  render() {
    const { status } = this.props;
    const statusMessage = labels[status];

    return statusMessage ? (
      <span
        className="OrderStatusLabel"
        style={{
          display: 'flex',
          alignItems: 'center',
          color: this.getColor(),
        }}
      >
        <Icon className="OrderStatusLabel__status-icon" name="circle" />
        &nbsp;
        {statusMessage}
      </span>
    ) : null;
  }
}

OrderStatusLabel.propTypes = {
  status: PropTypes.oneOf(Object.values(OrderStatusClass)),
};

export default OrderStatusLabel;
