import { flow, getEnv, getRoot, types } from 'mobx-state-tree';

import Cart from '../models/cart/Cart';
import Modal from '../models/Modal';
import StatefulStore from '../models/StatefulStore';
import ProductAddedToCardModalType from '../types/ProductAddedToCartModalStyle';

const CartStore = StatefulStore.named('CartStore')
  .props({
    alsoPurchasedProductId: types.maybeNull(types.string),
    cart: types.maybeNull(Cart),
    cartButtonDisabled: types.optional(types.boolean, false),
    cartErrorModal: types.optional(Modal.named('CartErrorModal'), {}),
    cartModal: types.optional(
      Modal.named('CartModal')
        .props({
          openedManually: types.optional(types.boolean, true),
        })
        .actions((self) => ({
          toggle: (wasOpenedManually = true) => {
            self.isOpen = !self.isOpen;

            // Only set the manual open state when the modal opens. If it is updated from
            // automatic -> manual during the close toggle, the manual UI will flash quickly
            // during the fade animation.
            if (self.isOpen) {
              self.openedManually = !!wasOpenedManually;
            }
          },
          close: () => {
            self.isOpen = false;
          },
        })),
      {}
    ),
    popoverModal: types.optional(
      Modal.named('PopoverModal')
        .props({
          openedManually: types.optional(types.boolean, true),
        })
        .actions((self) => ({
          toggle: () => {
            self.isOpen = !self.isOpen;
          },
        })),
      {
        isOpen: false,
      }
    ),
    productQuantity: types.optional(types.number, 1),
  })
  .actions((self) => {
    const getCartConfiguration = () => {
      const modalType = getRoot(self).configStore.cart.type;
      const isRetailer = getRoot(self).accountStore.account.is_retailer;
      return isRetailer || modalType === ProductAddedToCardModalType.POPOVER;
    };

    return {
      setup: (cart) => {
        self.cart = cart;
        self.setLoading(false);
      },
      closeModal: () => {
        self.cartModal.close();
      },
      toggleModal: () => {
        const enablePopover = getCartConfiguration();

        if (enablePopover) {
          self.popoverModal.toggle();
        } else {
          self.cartModal.toggle(false);
        }
      },
      loadCart: flow(function* loadCart() {
        self.setLoading(true);

        try {
          self.cart = yield getEnv(self).apiWrapper.request(`cart`);

          if (self.cart.ifRenderModal) {
            self.toggleModal();
          }

          if (self.cart.ifRenderErrorModal) {
            self.cartErrorModal.toggle();
          }
        } catch (error) {
          self.setError(error);
          throw error;
        }

        self.setLoading(false);
      }),
      setBasket: flow(function* setBasket(basketId) {
        self.setLoading(true);

        try {
          const request = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`cart/set-basket`, {
              basketId,
            });
          self.cart = request.data;
        } catch (error) {
          self.setError(error);
          throw error;
        }

        self.setLoading(false);
      }),
      addToCart: flow(function* addToCart(
        productId,
        quantity,
        bundleProductIds,
        additionalServices,
        recurringProduct,
        includeActiveCategory = false
      ) {
        self.setLoading(true);
        const categoryStore = getRoot(self).categoryStore;
        let activeCategory = null;
        if (includeActiveCategory) {
          activeCategory = categoryStore.activeCategory
            ? categoryStore.activeCategory.id
            : null;
        }

        const recurringOrderCycleType =
          recurringProduct && recurringProduct.cycle
            ? recurringProduct.cycle
            : null;
        const recurringOrderCustomCycle =
          recurringProduct && recurringProduct.days
            ? recurringProduct.days
            : null;

        const payload = {
          id: productId,
          qty: quantity,
          bundleProductIds: bundleProductIds,
          additionalServices: additionalServices,
        };

        // Add payload parameters conditionally.
        if (activeCategory) {
          payload.activeCategory = activeCategory;
        }
        if (recurringOrderCycleType) {
          payload.recurringOrderCycleType = recurringOrderCycleType;
        }
        if (recurringOrderCustomCycle) {
          payload.recurringOrderCustomCycle = recurringOrderCustomCycle;
        }

        try {
          const request = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`cart/add`, payload);

          // Update last productId only when we re-enter modal somewhere in shop
          // We want to keep the first product reference for also purchased listing
          if (!self.cartModal.isOpen || !self.alsoPurchasedProductId) {
            self.alsoPurchasedProductId = productId;
          }
          self.cart = request.data;
        } catch (error) {
          self.setError(error);
          throw error;
        }

        self.setLoading(false);

        /**
         * Don't close modal if it's open already.
         * This happens when user adds product via
         * AlsoPurchasedSlider within the modal.
         */
        if (!self.cartModal.isOpen) {
          self.toggleModal(self.cartModal.isOpen);
        }
      }),
      addFromOrder: flow(function* addFromOrder(orderId) {
        self.setLoading(true);

        try {
          const request = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`cart/add-from-order`, {
              orderId: orderId,
            });
          self.cart = request.data;
        } catch (error) {
          self.setError(error);
          throw error;
        }

        self.setLoading(false);
        self.toggleModal();
      }),
      populate: flow(function* populate(products, showCartMatrix = false) {
        self.setLoading(true);
        try {
          const request = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`cart/populate`, { products });

          self.cart = request.data;
        } catch (error) {
          self.setError(error);
          throw error;
        }

        self.setLoading(false);

        if (!self.cartModal.isOpen && showCartMatrix) {
          self.toggleModal(self.cartModal.isOpen);
        }
      }),
      remove: flow(function* remove(params) {
        self.setLoading(true);
        self.cartButtonDisabled = true;
        try {
          const request = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`cart/remove`, params);

          self.cart = request.data;
        } catch (error) {
          self.setError(error);
          self.setLoading(false);
          self.cartButtonDisabled = false;
          throw error;
        }

        self.setLoading(false);
        self.cartButtonDisabled = false;
      }),
      updateProductQuantity: (quantity) => (self.productQuantity = quantity),
      toggleCartButton: () => {
        self.cartButtonDisabled = !self.cartButtonDisabled;
      },
    };
  })
  .views((self) => ({
    getCartRemovedProducts: () => {
      return self.cart?.removed_products;
    },
  }));

export default CartStore;
