import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';

import { modelOf } from '../../../prop-types';
import Category from '../../../models/Category';
import RouteService from '../../../services/RouteService';
import SectionStore from '../../../store/SectionStore';

const defaultImagePath = '/img/no_image_available.png';

const NavigationCategoryImage = ({ sectionStore, routeService, category }) => {
  const getImage = (name, image) => {
    return (
      <div className="NavigationCategoryImage__image">
        <img src={image} alt={name} title={name} />
      </div>
    );
  };

  return (
    <div key={category.id} className="NavigationCategoryImage">
      <Link
        className="CategoryLink"
        to={routeService.getPath(category.path, sectionStore.activeSection)}
      >
        {getImage(category.navigationName, category.image || defaultImagePath)}
        <div className="NavigationCategoryImage__name">
          {category.navigationName}
        </div>
      </Link>
    </div>
  );
};

NavigationCategoryImage.propTypes = {
  sectionStore: modelOf(SectionStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  category: modelOf(Category).isRequired,
};

export default inject(
  'sectionStore',
  'routeService'
)(observer(NavigationCategoryImage));
