import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import globalTranslations from '../../../i18n/globalTranslations';
import { createForm } from '../../../models/Form';
import { createFormField } from '../../../models/FormField';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import CouponStore from '../../../store/CouponStore';
import ValidationStatus, {
  errorMap,
} from '../../../types/form/ValidationStatus';
import RequestState from '../../../types/RequestState';
import { createErrorModel } from '../../../util/error';
import { isEmail } from '../../../util/formValidators';
import FormGroupField from '../../form/FormGroupField';
import ErrorHandler from '../../loader/ErrorHandler';
import PrivacyStatementLink from '../../privacy-statement/PrivacyStatementLink';

const VIEWS = {
  DATA: 'DATA',
  EMAIL: 'EMAIL',
};

@observer
class CouponModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      requestId: 0,
      requestState: RequestState.NONE,
      retrievedCode: null,
      sendCouponLoading: false,
      setCouponLoading: false,
      showEmailFormForRetrieve: false,
      status: ValidationStatus.NONE,
      successMessage: null,
      view: VIEWS.DATA,
    };

    const fields = {
      email: createFormField({}, [
        isEmail(<FormattedMessage {...globalTranslations.emailTitle} />),
      ]),
    };

    this.form = createForm(fields);
  }

  changeView = (view) => {
    this.setState({ view });
  };

  retrieveCoupon = () => {
    const { couponStore, activatedCoupon } = this.props;
    this.setState({ requestState: RequestState.LOADING });

    couponStore
      .retrieveCoupon(
        activatedCoupon.coupon_id,
        activatedCoupon.activation_id,
        this.form.fields.get('email').value
      )
      .then((response) => {
        this.setState({
          status: ValidationStatus.SUCCESS,
          requestState: RequestState.LOADED,
          retrievedCode: response.data.code,
          error: null,
          requestId: this.state.requestId + 1,
        });
      })
      .catch((error) => {
        const status = error.response ? error.response.status : 500;

        this.setState({
          requestState: RequestState.ERROR,
          status: errorMap[status],
          requestId: this.state.requestId + 1,
          error,
        });
      });
  };

  setCoupon = () => {
    const { couponStore, activatedCoupon } = this.props;
    this.setState({ requestState: RequestState.LOADING });

    const code = this.state.retrievedCode || activatedCoupon.code;
    couponStore
      .setCoupon(code)
      .then(() => {
        this.setState({
          successMessage: (
            <FormattedMessage
              id="coupon.setSuccess"
              defaultMessage="The campaign code was activated successfully!"
            />
          ),
          status: ValidationStatus.SUCCESS,
          requestState: RequestState.LOADED,
          requestId: this.state.requestId + 1,
          error: null,
        });
      })
      .catch((error) => {
        const status = error.response ? error.response.status : 500;

        this.setState({
          requestState: RequestState.ERROR,
          status: errorMap[status],
          requestId: this.state.requestId + 1,
          error,
        });
      });
  };

  sendCoupon = () => {
    const { couponStore, activatedCoupon } = this.props;
    this.setState({ requestState: RequestState.LOADING });

    couponStore
      .sendCoupon(this.form.fields.get('email').value, activatedCoupon.code)
      .then(() => {
        this.setState({
          successMessage: (
            <FormattedMessage
              id="coupon.emailSuccess"
              defaultMessage="The discount coupon information has been sent to your email."
            />
          ),
          status: ValidationStatus.SUCCESS,
          requestState: RequestState.LOADED,
          requestId: this.state.requestId + 1,
          error: null,
        });
      })
      .catch((error) => {
        const status = error.response ? error.response.status : 500;

        this.setState({
          requestState: RequestState.ERROR,
          status: errorMap[status],
          requestId: this.state.requestId + 1,
          error,
        });
      });
  };

  showEmailForm = () => this.setState({ showEmailFormForRetrieve: true });

  getEmailForm = (onSubmit, buttonMessage) => {
    return (
      <Form
        className="CouponModal__email-form"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          this.form.validate();
          if (this.form.valid) {
            onSubmit();
          }
        }}
      >
        <FormGroupField
          field={this.form.fields.get('email')}
          label={<FormattedMessage {...globalTranslations.emailTitle} />}
          formName="CouponModal__email-form"
          fieldName="email"
          type="email"
        />
        <Button
          color="primary"
          block
          disabled={
            this.form.valid === false ||
            this.state.requestState === RequestState.LOADING
          }
        >
          {buttonMessage}
        </Button>
      </Form>
    );
  };

  getSetCouponButton() {
    return (
      <Button
        block
        color="primary"
        onClick={this.setCoupon}
        disabled={this.state.requestState === RequestState.LOADING}
      >
        <FormattedMessage
          id="coupon.useNow"
          defaultMessage="I want to activate my code now"
        />
      </Button>
    );
  }

  getDataView = () => {
    const { activatedCoupon } = this.props;
    if (this.state.retrievedCode) {
      return (
        <>
          <Alert color="info" className="CouponModal__retrieved-code">
            {this.state.retrievedCode}
          </Alert>
          {this.getSetCouponButton()}
        </>
      );
    } else if (activatedCoupon.code) {
      return (
        <>
          {this.getSetCouponButton()}
          <Button
            block
            color="secondary"
            onClick={() => this.changeView(VIEWS.EMAIL)}
          >
            <FormattedMessage
              id="coupon.useLater"
              defaultMessage="I want to use my code later"
            />
          </Button>
        </>
      );
    } else if (activatedCoupon.activation_id) {
      const message = (
        <FormattedMessage
          id="coupon.fetchDiscountCode"
          defaultMessage="Fetch discount code"
        />
      );
      return this.state.showEmailFormForRetrieve ? (
        this.getEmailForm(this.retrieveCoupon, message)
      ) : (
        <>
          <div className="CouponModal__retrieve-privacy-statement">
            <div>
              <FormattedMessage
                id="coupon.emailSubscribeNotice"
                defaultMessage="By giving us your email address you subscribe to our newsletter."
              />{' '}
              <PrivacyStatementLink />.
            </div>
          </div>
          <Button block color="primary" onClick={this.showEmailForm}>
            {message}
          </Button>
        </>
      );
    } else {
      return null;
    }
  };

  getEmailView = () => {
    return (
      <>
        {this.getEmailForm(
          this.sendCoupon,
          <FormattedMessage {...globalTranslations.sendTitle} />
        )}
        <Button
          block
          color="secondary"
          onClick={() => this.changeView(VIEWS.DATA)}
        >
          <FormattedMessage
            id="coupon.showInformation"
            defaultMessage="Show coupon information"
          />
        </Button>
      </>
    );
  };

  getErrorMessages = () => {
    const errors = Object.values(this.state.error.response.data.errors);
    return errors.length > 0 && errors;
  };

  renderError = () => {
    const { status, error, requestId } = this.state;
    const errors = error && this.getErrorMessages();
    let message = null;

    switch (status) {
      case ValidationStatus.VALIDATION_ERROR:
        message = errors && (
          <Alert color="danger" key={requestId}>
            {errors.flat().map((errorMessages) => (
              <div key={errorMessages}>{errorMessages}</div>
            ))}
          </Alert>
        );
        break;
      case ValidationStatus.UNEXPECTED_ERROR:
        message =
          (error && <ErrorHandler error={createErrorModel(error)} />) || null;
        break;
      case ValidationStatus.NONE:
      case RequestState.LOADING:
      default:
        message = null;
        break;
    }

    return message;
  };

  render() {
    const { accountStore, activatedCoupon, toggle, isOpen } = this.props;
    const { view, successMessage, error } = this.state;

    return (
      <Modal
        className="CouponModal"
        size="lg"
        toggle={toggle}
        isOpen={isOpen}
        backdrop="static"
      >
        <ModalHeader toggle={toggle} />
        <ModalBody>
          {successMessage ? (
            <Alert color="success">{successMessage}</Alert>
          ) : (
            <>
              {error && this.renderError()}
              <div
                dangerouslySetInnerHTML={{
                  __html: activatedCoupon.html,
                }}
                className={classNames(
                  'CouponModal__contents',
                  `CouponModal__contents--${view}`
                )}
              />
              {!accountStore.loggedIn && (
                <>
                  {view === VIEWS.DATA
                    ? this.getDataView()
                    : this.getEmailView()}
                </>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            <FormattedMessage {...globalTranslations.closeTitle} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

CouponModal.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  couponStore: modelOf(CouponStore).isRequired,
  activatedCoupon: PropTypes.shape({
    coupon_id: PropTypes.number.isRequired,
    html: PropTypes.string.isRequired,
    code: PropTypes.string,
    activation_id: PropTypes.number,
  }),
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default inject('accountStore', 'couponStore')(CouponModal);
