import { types } from 'mobx-state-tree';

const ValidationStatus = {
  NONE: 'NONE',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  CONFLICT: 'CONFLICT',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
};

export const errorMap = {
  200: ValidationStatus.SUCCESS,
  400: ValidationStatus.ERROR,
  409: ValidationStatus.CONFLICT,
  422: ValidationStatus.VALIDATION_ERROR,
  500: ValidationStatus.UNEXPECTED_ERROR,
};

export default ValidationStatus;

export const ValidationStatusType = types.enumeration(
  'ValidationStatusType',
  Object.values(ValidationStatus)
);
