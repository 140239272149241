import { inject, observer } from 'mobx-react';
import React from 'react';

import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import CouponModal from '../CouponModal';

const ActivatedCoupons = ({ accountStore }) => {
  const removeActiveCoupon = (activatedCoupon) => {
    if (accountStore.session) {
      accountStore.session.removeCoupon(activatedCoupon);
    }
  };

  return (
    <div className="ActivatedCoupons">
      {accountStore.session &&
        accountStore.session.activated_coupons
          .slice(0, 1)
          .map((activatedCoupon) => (
            <CouponModal
              key={activatedCoupon.code}
              toggle={() => removeActiveCoupon(activatedCoupon)}
              isOpen={true}
              activatedCoupon={activatedCoupon}
            />
          ))}
    </div>
  );
};

ActivatedCoupons.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
};

export default inject('accountStore')(observer(ActivatedCoupons));
