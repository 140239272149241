import { types } from 'mobx-state-tree';

const ShippingDeliveryTimeType = {
  HOURS: 'HOURS',
  BUSINESS_DAYS: 'BUSINESS_DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
};

export const ShippingDeliveryTimeCEFACTMap = {
  [ShippingDeliveryTimeType.HOURS]: 'HUR',
  [ShippingDeliveryTimeType.BUSINESS_DAYS]: 'DAY',
  [ShippingDeliveryTimeType.WEEKS]: 'WEE',
  [ShippingDeliveryTimeType.MONTHS]: 'MON',
};

export default ShippingDeliveryTimeType;

export const ShippingDeliveryTimeTypeType = types.enumeration(
  'ShippingDeliveryTimeType',
  Object.values(ShippingDeliveryTimeType)
);

export const ShippingDeliveryTimeCEFACTType = types.enumeration(
  'ShippingDeliveryTimeCEFACTMap',
  Object.values(ShippingDeliveryTimeCEFACTMap)
);
