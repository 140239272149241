import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import AccountModalLink from '../AccountModalLink';
import AccountModalTab from '../../../types/AccountModalTab';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

const RegisterLink = ({ configStore, prefix }) => {
  if (!configStore.registration.enabled) {
    return null;
  }

  return (
    <>
      {(!configStore.account.disableLogin && prefix) || null}
      <AccountModalLink className="RegisterLink" tab={AccountModalTab.REGISTER}>
        <FormattedMessage {...globalTranslations.accountCreateSentence} />
      </AccountModalLink>
    </>
  );
};

RegisterLink.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  prefix: PropTypes.string,
};

export default inject('configStore')(observer(RegisterLink));
