import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import NotFoundPage from '../../../pages/NotFoundPage';
import HomePage from '../../../pages/HomePage';
import Paths from '../../../types/Paths';
import useRoutes from '../../../hooks/routes/useRoutes';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import SectionStore from '../../../store/SectionStore';
import SectionAwareRoute from '../SectionAwareRoute';
import AccountStore from '../../../store/AccountStore';

const LanguageDependantRoutes = ({
  accountStore,
  configStore,
  sectionStore,
}) => {
  const match = useRouteMatch();

  const getAppRoutes = () =>
    useRoutes({
      accountStore,
      configStore,
      sections: sectionStore.sections,
      match: match.path,
    });

  return (
    <Switch>
      <Route key={Paths.FrontPage} exact path={match.path}>
        <SectionAwareRoute component={<HomePage />} />
      </Route>
      {getAppRoutes()}
      <Route key={Paths.NotFoundPage} path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

LanguageDependantRoutes.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
};

export default inject(
  'accountStore',
  'configStore',
  'sectionStore'
)(observer(LanguageDependantRoutes));
