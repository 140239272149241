import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Sticky from 'react-sticky-el';
import { Col, Container, Row } from 'reactstrap';

import ScrollableAnchor from '../../components/anchor/ScrollableAnchor';
import ButtonLink from '../../components/common/ButtonLink';
import HelperNavigation from '../../components/common/HelperNavigation';
import PageTitle from '../../components/common/PageTitle';
import ScrollReset from '../../components/common/ScrollReset';
import WysiwygContent from '../../components/common/WysiwygContent';
import CanonicalLink from '../../components/head/CanonicalLink';
import SEOTitle from '../../components/head/SEOTitle';
import GenericLayout from '../../components/layout/GenericLayout';
import Page from '../../components/layout/Page';
import ContentForState from '../../components/loader/ContentForState';
import ManufacturerLink from '../../components/manufacturer/ManufacturerLink';
import globalTranslations from '../../i18n/globalTranslations';
import { modelOf } from '../../prop-types';
import RouteService from '../../services/RouteService';
import ConfigStore from '../../store/ConfigStore';
import ManufacturerStore from '../../store/ManufacturerStore';
import UIStore from '../../store/UIStore';
import CommonPage from '../../types/CommonPage';
import Paths from '../../types/Paths';
import RequestState from '../../types/RequestState';
import { scrollToElementById } from '../../util/dom';

const STYLES = {
  DETAILED: '0',
  ONLY_NAME: '1',
  ONLY_LOGO: '2',
};

@observer
class ManufacturerListPage extends Component {
  constructor(props) {
    super(props);
    const { manufacturerStore } = this.props;

    if (
      !manufacturerStore.loadedWithContent &&
      manufacturerStore.state !== RequestState.LOADING
    ) {
      manufacturerStore.loadManufacturers({ excludeContent: false });
    }
  }

  getBreadcrumbsPath = () => {
    const { intl, routeService } = this.props;

    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.ourBrandsTitle),
        url: Paths.ManufacturerList,
      },
    ]);
  };

  getAlphabeticalLinkForManufacturer = (manufacturer) => (
    <div key={manufacturer.id}>
      <ButtonLink onClick={() => scrollToElementById(manufacturer.id)}>
        {manufacturer.name.charAt(0).toUpperCase()}
      </ButtonLink>
    </div>
  );

  getAlphabeticalIndex = () => {
    const { manufacturerStore } = this.props;
    return manufacturerStore.manufacturersWithProducts.reduce(
      (letters, manufacturer, index, manufacturers) => {
        if (index === 0) {
          letters.push(this.getAlphabeticalLinkForManufacturer(manufacturer));
          return letters;
        }

        const previousManufacturer = manufacturers[index - 1];
        if (
          manufacturer.name.charAt(0).toUpperCase() !==
          previousManufacturer.name.charAt(0).toUpperCase()
        ) {
          letters.push(this.getAlphabeticalLinkForManufacturer(manufacturer));
        }
        return letters;
      },
      []
    );
  };

  getImage = (manufacturer) => {
    return (
      manufacturer.image && (
        <Col
          key={manufacturer.id}
          sm="6"
          md="4"
          lg="3"
          className="ManufacturerListPage__listing-image-column"
        >
          <ManufacturerLink manufacturer={manufacturer}>
            <div className="ManufacturerListPage__listing-image-column-content">
              <img
                src={manufacturer.image}
                alt={manufacturer.name}
                loading="lazy"
              />
            </div>
          </ManufacturerLink>
        </Col>
      )
    );
  };

  getName = (manufacturer) => {
    return (
      <Col
        key={manufacturer.id}
        md="6"
        lg="4"
        className="ManufacturerListPage__listing-header-name"
      >
        <ScrollableAnchor id={manufacturer.id} />
        <ManufacturerLink manufacturer={manufacturer} />
      </Col>
    );
  };

  getDetailedManufacturer = (manufacturer) => (
    <Row className="ManufacturerListPage__listing" key={manufacturer.id}>
      <Col
        key={manufacturer.id}
        md="2"
        className="ManufacturerListPage__listing-image-column"
      >
        {manufacturer.image && (
          <ManufacturerLink manufacturer={manufacturer}>
            <div className="ManufacturerListPage__listing-image-column-content">
              <img
                src={manufacturer.image}
                alt={manufacturer.name}
                loading="lazy"
              />
            </div>
          </ManufacturerLink>
        )}
      </Col>
      <Col md="10" className="ManufacturerListPage__listing-info-column">
        <ScrollableAnchor id={manufacturer.id} />
        <div className="ManufacturerListPage__listing-info-column-content">
          <div className="ManufacturerListPage__listing-header">
            <ManufacturerLink manufacturer={manufacturer}>
              <h2>{manufacturer.name}</h2>
            </ManufacturerLink>
          </div>
          <div className="ManufacturerListPage__listing-description">
            {manufacturer.description_html && (
              <WysiwygContent html={manufacturer.description_html} />
            )}
          </div>
          <div className="ManufacturerListPage__listing-footer">
            <ManufacturerLink manufacturer={manufacturer}>
              <FormattedMessage
                id="manufacturer.browserManufacturerProducts"
                defaultMessage="Browse brands' products"
              />{' '}
              &raquo;
            </ManufacturerLink>
          </div>
        </div>
      </Col>
    </Row>
  );

  getManufacturersAlphabetically = () => {
    const { manufacturerStore } = this.props;

    return manufacturerStore.manufacturersWithProducts.reduce(
      (group, manufacturer) => {
        const title = manufacturer.name.charAt(0).toLowerCase();
        if (!group[title]) {
          group[title] = [];
        }
        group[title].push(manufacturer);
        return group;
      },
      {}
    );
  };

  getManufacturerListing = () => {
    const { configStore, manufacturerStore } = this.props;
    let manufacturerCols;

    switch (configStore.manufacturerListPage.style) {
      case STYLES.ONLY_NAME:
        manufacturerCols = (
          <Row className="ManufacturerListPage__listing">
            {Object.entries(this.getManufacturersAlphabetically()).map(
              ([key, value]) => {
                return (
                  <Col
                    key={key}
                    xs="12"
                    className="ManufacturerListPage__listing-group"
                  >
                    <Row className="ManufacturerListPage__listing-group-title">
                      <Col xs="12">{key.toUpperCase()}</Col>
                    </Row>
                    <Row className="ManufacturerListPage__listing-group-data">
                      {value.map((manufacturer, j) => {
                        if (!manufacturer || !manufacturer.name) {
                          return null;
                        }
                        return this.getName(manufacturer);
                      })}
                    </Row>
                  </Col>
                );
              }
            )}
          </Row>
        );
        break;
      case STYLES.ONLY_LOGO:
        manufacturerCols = (
          <Row className="ManufacturerListPage__listing">
            {manufacturerStore.manufacturersWithProducts.map((manufacturer) => {
              if (!manufacturer || !manufacturer.image) {
                return null;
              }
              return this.getImage(manufacturer);
            })}
          </Row>
        );
        break;
      case STYLES.DETAILED:
      default:
        manufacturerCols = manufacturerStore.manufacturersWithProducts.map(
          (manufacturer) => this.getDetailedManufacturer(manufacturer)
        );
        break;
    }

    return manufacturerCols;
  };

  render() {
    const { manufacturerStore, uiStore, routeService, intl } = this.props;

    return (
      <Page
        name={CommonPage.MANUFACTURER_LIST}
        className="ManufacturerListPage"
      >
        <ScrollReset />
        <Container className="ManufacturerListPage__container">
          <GenericLayout
            content={
              <ContentForState
                state={manufacturerStore.state}
                error={manufacturerStore.lastError}
                forLoaded={() => {
                  return (
                    <>
                      <SEOTitle
                        title={intl.formatMessage(
                          globalTranslations.ourBrandsTitle
                        )}
                      />
                      <CanonicalLink
                        path={routeService.getPath(Paths.ManufacturerList)}
                      />
                      <HelperNavigation
                        breadcrumbsPath={this.getBreadcrumbsPath()}
                      />
                      <PageTitle>
                        <FormattedMessage
                          {...globalTranslations.ourBrandsTitle}
                        />
                      </PageTitle>
                      <Row>
                        <Col xs="10" md="11">
                          {this.getManufacturerListing()}
                        </Col>
                        <Col
                          xs="2"
                          md="1"
                          className="ManufacturerListPage__sidebar"
                        >
                          <Sticky
                            boundaryElement=".ManufacturerListPage__sidebar"
                            topOffset={-uiStore.headerHeight}
                            bottomOffset={uiStore.headerHeight}
                            stickyStyle={{
                              transform: `translateZ(0px) translateY(${uiStore.headerHeight}px)`,
                            }}
                            hideOnBoundaryHit={false}
                          >
                            <div className="ManufacturerListPage__alphabetical-index">
                              <div className="ManufacturerListPage__alphabetical-index-scroller">
                                {this.getAlphabeticalIndex()}
                              </div>
                            </div>
                          </Sticky>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              />
            }
          />
        </Container>
      </Page>
    );
  }
}

ManufacturerListPage.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  manufacturerStore: modelOf(ManufacturerStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(
  inject(
    'configStore',
    'manufacturerStore',
    'uiStore',
    'routeService'
  )(ManufacturerListPage)
);
