import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import { inject, observer } from 'mobx-react';
import { modelOf } from '../../../prop-types';
import AdStore from '../../../store/AdStore';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import AdZones from '../../../types/AdZones';
import BannerStyle from '../../../types/banner/BannerStyle';
import BannerSlider from '../BannerSlider/BannerSliderSlides';

const FooterBanner = ({
  adStore,
  configStore,
  uiStore,
  overrideSettings,
  shouldHaveMobile,
}) => {
  const ASPECT_RATIO = 2.85;

  useEffect(() => {
    maybeLoadBanners();
  }, []);

  const maybeLoadBanners = () => {
    adStore.loadAds(getAdParameters());
  };

  const getFooterBannerSettings = () => {
    const style = configStore.banner.footerBanner.style;
    let settings = {};

    switch (style) {
      case BannerStyle.INFINITE:
        settings = {
          infinite: true,
        };
        break;
      case BannerStyle.CENTERED:
        settings = {
          centerMode: true,
        };
        break;
      case BannerStyle.BOTH:
        settings = {
          infinite: true,
          centerMode: true,
        };
        break;
      default:
        settings = {};
        break;
    }

    return settings;
  };

  const bannerSettings = {
    ...overrideSettings,
    ...getFooterBannerSettings(),
  };

  const getAdParameters = () => {
    return {
      bannerZones: [AdZones.FOOTER_BANNER],
    };
  };

  return (
    <BannerSlider
      className="FooterBanner"
      adZone={AdZones.FOOTER_BANNER}
      searchParams={getAdParameters()}
      aspectRatio={ASPECT_RATIO}
      maximumCrossAxisSize={
        uiStore.isMobile
          ? shouldHaveMobile
            ? window.innerWidth
            : window.innerWidth / ASPECT_RATIO
          : configStore.banner.mainBanner.height
      }
      overrideSettings={bannerSettings}
    />
  );
};

FooterBanner.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  overrideSettings: PropTypes.object,
  shouldHaveMobile: PropTypes.bool,
};

FooterBanner.defaultProps = {
  shouldHaveMobile: false,
};

export default withRouter(
  inject('adStore', 'configStore', 'uiStore')(observer(FooterBanner))
);
