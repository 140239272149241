import { useCallback } from 'react';

const useBannerInteractions = () => {
  let interactionStartX = 0;
  let interactionStartY = 0;

  const handleInteraction = useCallback(
    (event) => {
      const currentX = event.screenX;
      const currentY = event.screenY;
      const passiveEvents = [
        'onClick',
        'onTouchStart',
        'onTouchMove',
        'onWheel',
      ];
      const diffX = Math.abs(interactionStartX - currentX);
      const diffY = Math.abs(interactionStartY - currentY);

      // Don't swipe if Y-movement is bigger than X-movement
      if (diffX < diffY) {
        event.preventDefault();
      }

      if (diffX > 40) {
        event.preventDefault();
      }

      if (!passiveEvents.includes(event._reactName)) {
        event.preventDefault();
      }
    },
    [interactionStartX, interactionStartY]
  );

  const handleInteractionDown = useCallback(
    (event) => {
      interactionStartX = event.screenX;
      interactionStartY = event.screenY;
    },
    [interactionStartX, interactionStartY]
  );

  return [handleInteraction, handleInteractionDown];
};

export default useBannerInteractions;
