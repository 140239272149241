// Based on https://github.com/kvz/locutus/blob/master/src/php/strings/number_format.js
function toFixedFix(n, prec) {
  let k = Math.pow(10, prec);
  return '' + (Math.round(n * k) / k).toFixed(prec);
}

export function formatNumber(number, decimals, decPoint, thousandsSep) {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  let n = !isFinite(+number) ? 0 : +number;
  let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  let sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
  let dec = typeof decPoint === 'undefined' ? '.' : decPoint;

  let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }

  return s.join(dec);
}

/**
 * @param {double} prices
 * @param {number} precision
 * @returns {double} number rounded by precision
 */
export function roundWithPrecision(number, precision) {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

export function formatPrice(price, currency) {
  if (!currency) {
    return '';
  }
  return (
    currency.symbol_left +
    formatNumber(
      roundWithPrecision(price, currency.precision),
      currency.decimal_places,
      currency.decimal_point,
      currency.thousands_point
    ) +
    currency.symbol_right
  );
}

/**
 * @param {Array.<double>} prices
 * @param {number} precision
 * @returns double with currency precision
 */
export function calculateTotal(prices, precision) {
  return prices.reduce((sum, current) => {
    return sum + roundWithPrecision(current, precision);
  }, 0);
}

export function calculateNearestProductQuantityWithQuantityStep(
  quantity,
  quantityStep
) {
  return roundWithPrecision(quantity / quantityStep, 2);
}

export function calculateProductPackageSizeWithQuantity(
  quantityStep,
  quantity
) {
  return Number((quantityStep * quantity).toFixed(1));
}

export const isFloat = (value) => {
  return Number(value) === value && value % 1 !== 0;
};
